import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, InputNumber, Steps, DatePicker, Empty, message} from 'antd';
import { BiPlusCircle } from "react-icons/bi";

import api from '../../../../api';

import  './add-plan.css';

function AddPlan(props){
    const {wallet, setPlans}= props;
    const [modalNewPlan, setModalNewPlan] = useState(false);
    const [price, setPrice] = useState('');
    const [loading, setLoading] = useState(false)

    const onFinish = async values => {
        // console.log(values)
        // console.log(price.replace("R$ ",'').replace(/\./g,'').replace(',','.'))
        setLoading(true)
        let newPlan = {
            wallet: wallet._id,
            name: values.name,
            slug: values.name.slugify(),
            price: price.replace("R$ ",'').replace(/\./g,'').replace(',','.')
        }

        console.log(newPlan)
        
        const data = await api.post('/plans', newPlan).then( async response => { 
            const {data} = await api.get(`/plans/wallet/${wallet._id}`);
            setPlans(data)
            setModalNewPlan(false)
            setLoading(false)
            message.success('Plano criado com suceesso!');
        }).catch(error => { 
            console.log(error) 
            setModalNewPlan(false)
            message.error('Erro ao criar plano.');
        })
    };

    function changePrice( value ){
        let v = value;
            v=v.replace(/\D/g,"")  
            v=v.replace(/(\d{1})(\d{14})$/,"$1.$2") 
            v=v.replace(/(\d{1})(\d{11})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{8})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{5})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{1,2})$/,"$1,$2")

        setPrice( "R$ "+v )
    }

    function onChange(value) {
        console.log('changed', value);
      }
    
    return(
        <div>
            <div className="add-plan" onClick={()=>setModalNewPlan(true)}>
                <BiPlusCircle size="20px" />
                <span>Criar novo plano</span>
            </div> 

            <Modal
                visible={modalNewPlan}
                title="Criar novo plano"
                onCancel={()=>setModalNewPlan(false)}
                footer={false}
                centered
                width={800}
                maskClosable={false}
            >
                
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFinish}
                >
                    <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome do plano é obrigatório' }]} >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Valor" >
                        <Input onChange={(e)=>changePrice(e.target.value)} value={price} />
                        {/* <InputNumber
                            value={price}
                            formatter={  value => {
                                let v = value;
                                v=v.replace(/\D/g,"")  
                                v=v.replace(/(\d{1})(\d{14})$/,"$1.$2") 
                                v=v.replace(/(\d{1})(\d{11})$/,"$1.$2")  
                                v=v.replace(/(\d{1})(\d{8})$/,"$1.$2")  
                                v=v.replace(/(\d{1})(\d{5})$/,"$1.$2")  
                                v=v.replace(/(\d{1})(\d{1,2})$/,"$1,$2")
                                return "R$ "+v
                            }}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={onChange}
                            style={{ width: '50%' }}  
                            /> */}
                    </Form.Item>
                    
                    <div className="add-customer-form-footer">
                        <Button type="primary" htmlType="submit" className="green" loading={loading} style={{width: "200px"}}>Adicionar Plano</Button>
                    </div>

                        </Form>

            </Modal>
             
        </div>
    )       
}

export default AddPlan;