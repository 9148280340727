import React, { useContext, useState, useEffect } from 'react';
import { Tag, Empty, Checkbox, Button, Select, Form, message, Modal, Spin, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import MonetaryConverter from '../../../helpers/monetaryConverter';
import FormatDocument from '../../../helpers/formatDocument';
import StatusMask from '../../../helpers/statusMask';
import WeekDayMask from '../../../helpers/weekDayMask';
import Priority from '../../../helpers/priority';
import './list.css';
import api from '../../../api';
import { Context } from '../../../context/AuthContext';

function List(props) {
    const { userData } = useContext(Context);
    const { goals, setGoals, selectGoal } = props;
    const [responsibles, setResponsibles] = useState([]);
    const [responsible, setResponsible] = useState('');
    //const [selectedCustomers, setSelectedCustomers] = useState([])
    const [loadingModal, setLoadgingModal] = useState(false)

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);

    return (
        <>

            <div className={`goals-header`}>
                <div className="goals-header-item priority"></div>
                {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                    <div className="goals-header-item value"><span>Vendedor</span></div>
                }
                <div className="goals-header-item company"><span>Empresa</span></div>
                <div className="goals-header-item date"><span>Dia da semana</span></div>
                <div className="goals-header-item date"><span>Data do Interesse</span></div>
                <div className="goals-header-item proposal"><span>Proposta</span></div>
                <div className="goals-header-item origin"><span>Origem</span></div>
                <div className="goals-header-item sell"><span>Vender</span></div>
                <div className="goals-header-item plan"><span>Valores</span></div>
                <div className="goals-header-item status"><span>Status</span></div>
                <div className="goals-header-item edit"><span>Editar</span></div>

            </div>


            {goals.length == 0
                ? <Empty description="Nenhum resultado." style={{ marginTop: "50px" }} />
                : goals.map((goal) => (
                    <div className={`goal`} >
                        <div className="goal-item priority">
                            <Priority priority={goal.priority} tooltip />
                        </div>
                        {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                            <div className="goal-item responsible">
                                {goal.responsible
                                    ? <span className="responsible-name">{goal.responsible.name}</span>
                                    : '---'
                                }
                            </div>
                        }
                        <div className="goal-item company">
                            <span className="responsible-name">{goal.company}</span>
                        </div>
                        <div className="goal-item date">
                            <WeekDayMask day={goal.weekDay} />
                        </div>
                        <div className="goal-item date">
                            <span>{goal.interestDate}</span>
                        </div>
                        <div className="goal-item proposal">
                            <span>{goal.proposal ? 'Sim' : 'Não'}</span>
                        </div>
                        <div className="goal-item origin">
                            <span>{goal.origin}</span>
                        </div>
                        <div className="goal-item sell">
                            <span>{goal.sell}</span>
                        </div>
                        <div className="goal-item plan">
                            {goal.values
                                ? <MonetaryConverter quantity={goal.values} class="plan-price" />
                                : <span>-</span>
                            }
                        </div>
                        <div className="goal-item status">
                            <Tooltip title={goal.obs}>
                                <Tag className={`tag ${goal.status} customer-list`}><StatusMask status={goal.status} /></Tag>
                            </Tooltip>
                        </div>
                        <div className="goal-item edit">
                            <Button type="default" shape="circle" icon={<EditOutlined />} size="small" onClick={() => selectGoal(goal._id)} />
                        </div>
                    </div>
                )
                )}


            <Modal
                header={false}
                footer={false}
                keyboard={false}
                closable={false}
                centered={true}
                visible={loadingModal}
            >
                <div className="example" style={{ textAlign: 'center' }}>
                    <Spin tip="Atualizando metas..." />
                </div>
            </Modal>
        </>
    )
}

export default List;
