import React, { useContext, useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import MaskedInput from 'antd-mask-input'
import { Row, Col, Form, Input, TextArea, Button, Select, Modal, Tag, Timeline, Card, Popover, DatePicker, message, Spin, Tooltip, Upload, Radio } from 'antd';
import { BiEdit, BiX, BiBell } from "react-icons/bi";
import { EditOutlined, FileAddOutlined, FileExcelOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import Priority from '../../../helpers/priority';
import { Context } from '../../../context/AuthContext';
//import statusList from '../../../helpers/status.json';
import MonetaryConverter from '../../../helpers/monetaryConverter';
import FormatDocument from '../../../helpers/formatDocument';
import FormatPhone from '../../../helpers/formatPhone';
import StatusMask from '../../../helpers/statusMask';
import WeekDayMask from '../../../helpers/weekDayMask';
import api from '../../../api';

import cities from '../../../helpers/locales/cities.json';
import states from '../../../helpers/locales/states.json';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import './edit-customer.css';

function EditGoal(props) {
    const { userData } = useContext(Context);
    // Dados herdados
    const { goal } = props;
    let { responsible } = goal;
    const [editCustomer, setEditCustomer] = useState(false)
    const { TextArea } = Input;
    const [responsibles, setResponsibles] = useState([]);
    // Dados para atualizar
    const [price, setPrice] = useState('')
    const [readjustment, setReadjustment] = useState('');
    //const [newResponsible, setNewResponsible] = useState('');
    //const [status, setStatus] = useState('');
    // Loadings e ações
    const [formLoad, setFormLoad] = useState(false);

    // form
    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);

    const onFinish = async values => {
        setFormLoad(true)

        let newGoal = {
            responsible: userData.roles.includes('admin') ? values.responsible : goal.responsible,
            company: values.company,
            weekDay: values.weekDay,
            interestDate: readjustment,
            priority: values.priority,
            proposal: values.proposal === "sim" ? true : false,
            origin: values.origin,
            sell: values.sell,
            values: price.replace("R$ ", '').replace(/\./g, '').replace(',', '.'),
            status: values.status,
            obs: values.obs
        }

        await api.put(`/goals/${goal._id}`, newGoal)
            .then(async response => {
                form.resetFields();
                setFormLoad(false)
                setEditCustomer(false)
                //props.setGoal(response.data.goal)
                message.success('Meta atualizada!');
                // Reacarrega customers               
                let url = `/goals?&page=${props.page}`

                if (props.filters.weekDay)
                    url = url.concat(`&weekDay=${props.filters.weekDay}`)

                if (props.filters.status)
                    url = url.concat(`&status=${props.filters.status}`)

                if (props.filters.priority)
                    url = url.concat(`&priority=${props.filters.priority}`)

                if (props.filters.responsible)
                    url = url.concat(`&responsible=${props.filters.responsible}`)

                if (props.filters.search)
                    url = url.concat(`&search=${props.filters.search}`)

                const { data } = await api.get(url);
                props.setGoals(data.goals)
                props.setModalGoal(false)

            }).catch(error => {
                console.log(error)
            })

        // let newCustomer = {}

        // if (values.document == '' || values.document == null || values.document == undefined) newCustomer.document = customer.document

        // if (values.responsible) newCustomer.responsible = values.responsible
        // if (values.status) newCustomer.status = values.status
        // if (values.priority) newCustomer.priority = values.priority

        // if (values.name) newCustomer.name = values.name
        // if (values.company) newCustomer.company = values.company
        // if (values.document) newCustomer.document = values.document
        // if (values.email) newCustomer.email = values.email
        // if (values.city) newCustomer.city = values.city
        // if (values.state) newCustomer.state = values.state
        // if (values.phone1) newCustomer.phone1 = values.phone1
        // if (values.phone2) newCustomer.phone2 = values.phone2

        // if (values.wallet) newCustomer.wallet = values.wallet
        // if (values.plan) newCustomer.plan = values.plan
        // let oldPrice = customer.price
        // let newPrice = price.replace("R$ ", '').replace(/\./g, '').replace(',', '.')
        // if (newPrice != oldPrice) newCustomer.price = newPrice
        // if (readjustment) newCustomer.readjustment = readjustment
        // if (values.ages) newCustomer.ages = values.ages
        // if (values.lives) newCustomer.lives = values.lives

        // if (returnDate) newCustomer.returnDate = returnDate
        // if (values.obs) newCustomer.obs = values.obs

        // newCustomer.returnDateAlert = returnDateAlert
        // newCustomer.returnDateAlertType = "days"


        // if (Object.keys(newCustomer).length > 1) {
        //     const data = await api.put(`/customers/update/${customer._id}`, newCustomer)
        //         .then(async response => {
        //             form.resetFields();
        //             setFormLoad(false)
        //             setEditCustomer(false)
        //             props.setCustomer(response.data.customer)
        //             message.success('Cliente atualizado!');
        //             // Reacarrega customers               
        //             let url = `/customers?&page=${props.page}`

        //             if (props.filters.wallet)
        //                 url = url.concat(`&wallet=${props.filters.wallet}`)

        //             if (props.filters.status)
        //                 url = url.concat(`&status=${props.filters.status}`)

        //             if (props.filters.priority)
        //                 url = url.concat(`&priority=${props.filters.priority}`)

        //             if (props.filters.responsible)
        //                 url = url.concat(`&responsible=${props.filters.responsible}`)

        //             if (props.filters.search)
        //                 url = url.concat(`&search=${props.filters.search}`)

        //             const { data } = await api.get(url);
        //             props.setCustomers(data.customers)

        //         }).catch(error => {
        //             console.log(error)
        //         })

        // } else {
        //     setFormLoad(false)
        //     message.warning('Nenhuma alteração feita.')
        // }

        // setPlanFormLoad(true)
        // const data = await api.put(`/customers/plan/${customer._id}`, {
        //     "wallet": values.wallet,
        //     "plan": values.plan,
        // }).then( async response => { 
        //     form.resetFields();
        //     setPlanFormLoad(false)
        //     setPlanForm(false)
        //     props.setCustomer(response.data.customer)
        //     message.success('Resposável atualizado!');
        //     // Reacarrega customers
        //     const {data} = await api.get('/customers');
        //     props.setCustomers(data.customers)

        // }).catch(error => { 
        //     console.log(error) 
        // })
    };

    // const onFinishEdit = async values => {

    //     setEditFormLoad(true)
    //     const data = await api.put(`/customers/status/${customer._id}`, {
    //         "status": newStatus,
    //         "returnDage": newReturnDate,
    //         "obs": newObs
    //     }).then( async response => { 
    //         form.resetFields();
    //         setEditFormLoad(false)
    //         setEditForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Cliente atualizado!');
    //         // Reseta form
    //         setNewStatus('')
    //         setNewReturnDate('')
    //         setNewObs('')
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)
    //         // Recarrega totais
    //         const statusTotal = await api.get('/customers/totals/status');
    //         props.setStatusTotal(statusTotal.data);    
    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // const onFinishResponsible = async values => {
    //     setResponsibleFormLoad(true)
    //     const data = await api.put(`/customers/responsible/${customer._id}`, {
    //         "responsible": newResponsible
    //     }).then( async response => { 
    //         form.resetFields();
    //         setResponsibleFormLoad(false)
    //         setResponsibleForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Resposável atualizado!');
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)

    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // const onFinishPlan = async values => {
    //     console.log(values)
    //     setPlanFormLoad(true)
    //     const data = await api.put(`/customers/plan/${customer._id}`, {
    //         "wallet": values.wallet,
    //         "plan": values.plan,
    //     }).then( async response => { 
    //         form.resetFields();
    //         setPlanFormLoad(false)
    //         setPlanForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Resposável atualizado!');
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)

    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // function toggleEditForm(){
    //     setEditForm( !editForm )
    // }

    // function toggleResponsibleForm(){
    //     setResponsibleForm( !responsibleForm)
    // }

    // function togglePlanForm(){
    //     setPlanForm( !planForm)
    // }

    const { confirm } = Modal;

    function changePrice(value) {
        console.log(value)
        let v = value;
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{1})(\d{14})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{11})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2")

        setPrice("R$ " + v)
    }

    function changeEdiForm() {
        changePrice(goal.values)
        onChangeReadjustment("", goal.interestDate)
        setEditCustomer(!editCustomer)
    }

    function onChangeReadjustment(date, dateString) {
        setReadjustment(dateString)
    }

    return (
        <Modal
            visible={props.modalGoal}
            // title={customer.company}
            onCancel={() => { props.setModalGoal(false); setFormLoad(false); form.resetFields(); }}
            className="modal-customer"
            footer={false}
            width={800}
            centered
            maskClosable={false}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{
                    company: goal.company,
                    //interestDate: goal.interestDate,
                    obs: goal.obs,
                    origin: goal.origin,
                    priority: goal.priority,
                    sell: goal.sell,
                    status: goal.status,
                    weekDay: goal.weekDay
                }}
            >
                <Spin tip="Atualizando meta..." spinning={formLoad}>
                    <div className="modal-customer-header">
                        <div className="modal-customer-header-data">
                            {!editCustomer &&
                                <p><b>Criado dia:</b> <Moment format="DD/MM/YYYY">{goal.createdAt}</Moment> </p>
                            }

                            {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&

                                <p><b>Vendedor:</b>{editCustomer
                                    ? <Form.Item name="responsible">
                                        <Select placeholder="Selecione um vendedor" defaultValue={responsible ? responsible.name : ''} style={{ width: 150 }} showSearch>
                                            {responsibles.map((responsible) => (
                                                <Select.Option value={responsible._id}>{responsible.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    : responsible ? responsible.name : goal.responsible
                                }
                                    {!responsible && !editCustomer && <span>---</span>}
                                </p>

                            }

                            <p><b>Status:</b> {editCustomer
                                ? <Form.Item name="status">
                                    <Select placeholder="Selecione um status" defaultValue={goal.status} style={{ width: 150 }} showSearch>
                                        <Select.Option value="waiting">Aguardando</Select.Option>
                                        <Select.Option value="analysis">Em análise</Select.Option>
                                        <Select.Option value="return">Retorno</Select.Option>
                                        <Select.Option value="changing">Em troca</Select.Option>
                                    </Select>
                                </Form.Item>
                                : <Tag className={`tag ${goal.status}`}><StatusMask status={goal.status} /></Tag>}
                            </p>

                            <p><b>Prioridade:</b> {editCustomer
                                ? <Form.Item name="priority">
                                    <Select placeholder="Selecione" defaultValue={goal.priority} style={{ width: 100 }} showSearch>
                                        <Select.Option value="high">Alta</Select.Option>
                                        <Select.Option value="medium" select={true}>Media</Select.Option>
                                        <Select.Option value="low">Baixa</Select.Option>
                                    </Select>
                                </Form.Item>
                                : <Priority priority={goal.priority} text />}
                            </p>
                        </div>
                        <div className="modal-customer-header-actions" style={{ display: 'flex' }}>
                            <Button type="dashed" style={{ display: 'flex' }} onClick={(e) => changeEdiForm()}>
                                {!editCustomer
                                    ? <span>Editar</span>
                                    : <span>Cancelar</span>
                                }
                            </Button>
                            {editCustomer && <Button type="primary" style={{ marginLeft: '15px' }} className="green" htmlType="submit">Salvar Alterações</Button>}
                        </div>
                    </div>
                    <Row className="modal-customer-body">
                        <Col span={12} className="col-resume">
                            <p><b>Empresa:</b> {editCustomer ? <Form.Item name="company"><Input initialValue={goal.company} /></Form.Item> : goal.company}</p>

                            <p><b>Dia da semana:</b> {editCustomer ? <Form.Item name="weekDay">
                                <Select
                                    placeholder="Selecione o dia da semana"
                                    style={{ width: '60%' }}
                                    defaultValue={goal.weekDay}
                                >
                                    <Select.Option value="dom">Domingo</Select.Option>
                                    <Select.Option value="seg">Segunda-feira</Select.Option>
                                    <Select.Option value="ter">Terça-feira</Select.Option>
                                    <Select.Option value="qua">Quarta-feira</Select.Option>
                                    <Select.Option value="qui">Quinta-feira</Select.Option>
                                    <Select.Option value="sex">Sexta-feira</Select.Option>
                                    <Select.Option value="sab">Sábado</Select.Option>
                                </Select>
                            </Form.Item> : <WeekDayMask day={goal.weekDay} />}</p>

                            <p><b>Data do interesse:</b> {editCustomer ? <Form.Item name="interestDate">
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    placeholder="__/__/____"
                                    locale={locale}
                                    style={{ width: '100%' }, { borderRadius: '8px' }}
                                    //value={customer.readjustment}
                                    onChange={onChangeReadjustment}
                                    defaultValue={goal.interestDate ? moment(goal.interestDate, "DD/MM/YYYY") : ''}
                                />
                            </Form.Item> : goal.interestDate}</p>

                            <p><b>Proposta:</b> {editCustomer ? <Form.Item name="proposal">
                                <Select
                                    placeholder="Selecione"
                                    style={{ width: '60%' }}
                                    defaultValue={goal.proposal ? 'sim' : 'nao'}
                                >
                                    <Select.Option value="sim">Sim</Select.Option>
                                    <Select.Option value="nao">Não</Select.Option>
                                </Select>
                            </Form.Item> : goal.proposal ? 'Sim' : 'Não'}</p>

                            <p><b>Origem Listagem:</b> {editCustomer ? <Form.Item name="origin"><Input defaultValue={goal.origin} /></Form.Item> : goal.origin}</p>

                            <p><b>Vender:</b> {editCustomer ? <Form.Item name="sell"><Input defaultValue={goal.sell} /></Form.Item> : goal.sell}</p>

                            <p><b>Valores:</b> {editCustomer ? <Form.Item><Input defaultValue={price} value={price} onChange={(e) => changePrice(e.target.value)} /></Form.Item> :
                                goal.values
                                    ? <MonetaryConverter quantity={goal.values} class="plan-price" />
                                    : <span>-</span>

                            }</p>

                        </Col>
                        <Col span={12} className="col-plan" style={{ borderRight: 'none' }}>
                            <h3>Observações:</h3>
                            <p>{editCustomer ? <Form.Item name="obs"><TextArea style={{ height: '100px' }} defaultValue={goal.obs} /></Form.Item> : goal.obs}</p>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </Modal >
    )
}

export default EditGoal;