import React from 'react';
import { Card, Empty} from 'antd';
import './list.css';


function List (props){
    
    const { wallets, selectWallet } = props;
 
    return(
        wallets.length == 0
            ?  <Card style={{ width: 240 }} className="card"><Empty description="Nenhum resultado."/></Card>
            :  wallets.map((wallet) => (
                    <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={wallet.logo} />}
                        className="card"
                        onClick={ () => selectWallet(wallet._id) }
                    >   
                        <Card.Meta title={wallet.name} description="37 clientes" />
                    </Card>  
                )     
        )
    )
}

export default List;
