import React, { useContext, useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import MaskedInput from 'antd-mask-input'
import { Row, Col, Form, Input, TextArea, Button, Select, Modal, Tag, Timeline, Card, Popover, DatePicker, message, Spin, Tooltip, Upload, Radio } from 'antd';
import { BiEdit, BiX, BiBell } from "react-icons/bi";
import { EditOutlined, FileAddOutlined, FileExcelOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import Priority from '../../../helpers/priority';
import { Context } from '../../../context/AuthContext';
//import statusList from '../../../helpers/status.json';
import MonetaryConverter from '../../../helpers/monetaryConverter';
import FormatDocument from '../../../helpers/formatDocument';
import FormatPhone from '../../../helpers/formatPhone';
import StatusMask from '../../../helpers/statusMask';
import api from '../../../api';

import cities from '../../../helpers/locales/cities.json';
import states from '../../../helpers/locales/states.json';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import './edit-customer.css';

function EditCustomer(props) {
    const { userData } = useContext(Context);
    // Dados herdados
    const { customer } = props;
    let { responsible, contact, wallet, historic } = customer;
    const [editCustomer, setEditCustomer] = useState(false)
    const { TextArea } = Input;
    const [responsibles, setResponsibles] = useState([]);
    // Dados para atualizar
    const [phone1, setPhone1] = useState('')
    const [phone2, setPhone2] = useState('')
    const [price, setPrice] = useState('')
    const [readjustment, setReadjustment] = useState('');
    //const [newResponsible, setNewResponsible] = useState('');
    //const [status, setStatus] = useState('');
    const [returnDate, setReturnDate] = useState('');
    // Loadings e ações
    const [formLoad, setFormLoad] = useState(false);
    // const [editFormLoad, setEditFormLoad] = useState(false);

    // const [responsibleForm, setResponsibleForm] = useState(false);
    // const [responsibleFormLoad, setResponsibleFormLoad] = useState(false)

    // const [planForm, setPlanForm] = useState(false);
    // const [planFormLoad, setPlanFormLoad] = useState(false)

    const [returnDateAlert, setReturnDateAlert] = useState(0)

    // form
    const [form] = Form.useForm();

    // wallets
    const [wallets, setWallets] = useState([])
    const [plans, setPLans] = useState([])
    const [newWallet, setNewWallet] = useState([])
    const [editPlan, setEditPlan] = useState(false)
    useEffect(() => {
        (async () => {
            const { data } = await api.get('/wallets');
            setWallets(data);
        })();
    }, []);

    // Upload File
    const [uploadFileLoading, setUploadFileLoading] = useState(false)

    // plans
    async function changeWallet(walletId) {
        //setLoadingPlans(true)
        setNewWallet(walletId)

        const { data } = await api.get(`/plans/wallet/active/${walletId}`);
        //setLoadingPlans(false)
        if (data.length > 0) {
            setPLans(data);
            //setEmptyPlans(false)
        } else {
            //setEmptyPlans(true)
        }
    }

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);

    //const antIcon = <LoadingOutlined style={{ fontSize: '12px' }} spin />;

    function onChangeReturn(date, dateString) {
        setReturnDate(dateString)
    }

    const onFinish = async values => {
        console.log(values)
        setFormLoad(true)

        let newCustomer = {}

        if (values.document == '' || values.document == null || values.document == undefined) newCustomer.document = customer.document

        if (values.responsible) newCustomer.responsible = values.responsible
        if (values.status) newCustomer.status = values.status
        if (values.priority) newCustomer.priority = values.priority

        if (values.name) newCustomer.name = values.name
        if (values.company) newCustomer.company = values.company
        if (values.document) newCustomer.document = values.document
        if (values.email) newCustomer.email = values.email
        if (values.city) newCustomer.city = values.city
        if (values.state) newCustomer.state = values.state
        if (values.phone1) newCustomer.phone1 = values.phone1
        if (values.phone2) newCustomer.phone2 = values.phone2

        if (values.wallet) newCustomer.wallet = values.wallet
        if (values.plan) newCustomer.plan = values.plan
        let oldPrice = customer.price
        let newPrice = price.replace("R$ ", '').replace(/\./g, '').replace(',', '.')
        if (newPrice != oldPrice) newCustomer.price = newPrice
        if (readjustment) newCustomer.readjustment = readjustment
        if (values.ages) newCustomer.ages = values.ages
        if (values.lives) newCustomer.lives = values.lives

        if (returnDate) newCustomer.returnDate = returnDate
        if (values.obs) newCustomer.obs = values.obs

        newCustomer.returnDateAlert = returnDateAlert
        newCustomer.returnDateAlertType = "days"


        if (Object.keys(newCustomer).length > 1) {
            const data = await api.put(`/customers/update/${customer._id}`, newCustomer)
                .then(async response => {
                    form.resetFields();
                    setFormLoad(false)
                    setEditCustomer(false)
                    props.setCustomer(response.data.customer)
                    message.success('Cliente atualizado!');
                    // Reacarrega customers               
                    let url = `/customers?&page=${props.page}`

                    if (props.filters.wallet)
                        url = url.concat(`&wallet=${props.filters.wallet}`)

                    if (props.filters.status)
                        url = url.concat(`&status=${props.filters.status}`)

                    if (props.filters.priority)
                        url = url.concat(`&priority=${props.filters.priority}`)

                    if (props.filters.responsible)
                        url = url.concat(`&responsible=${props.filters.responsible}`)

                    if (props.filters.search)
                        url = url.concat(`&search=${props.filters.search}`)

                    const { data } = await api.get(url);
                    props.setCustomers(data.customers)

                }).catch(error => {
                    console.log(error)
                })

        } else {
            setFormLoad(false)
            message.warning('Nenhuma alteração feita.')
        }

        // setPlanFormLoad(true)
        // const data = await api.put(`/customers/plan/${customer._id}`, {
        //     "wallet": values.wallet,
        //     "plan": values.plan,
        // }).then( async response => { 
        //     form.resetFields();
        //     setPlanFormLoad(false)
        //     setPlanForm(false)
        //     props.setCustomer(response.data.customer)
        //     message.success('Resposável atualizado!');
        //     // Reacarrega customers
        //     const {data} = await api.get('/customers');
        //     props.setCustomers(data.customers)

        // }).catch(error => { 
        //     console.log(error) 
        // })
    };

    // const onFinishEdit = async values => {

    //     setEditFormLoad(true)
    //     const data = await api.put(`/customers/status/${customer._id}`, {
    //         "status": newStatus,
    //         "returnDage": newReturnDate,
    //         "obs": newObs
    //     }).then( async response => { 
    //         form.resetFields();
    //         setEditFormLoad(false)
    //         setEditForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Cliente atualizado!');
    //         // Reseta form
    //         setNewStatus('')
    //         setNewReturnDate('')
    //         setNewObs('')
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)
    //         // Recarrega totais
    //         const statusTotal = await api.get('/customers/totals/status');
    //         props.setStatusTotal(statusTotal.data);    
    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // const onFinishResponsible = async values => {
    //     setResponsibleFormLoad(true)
    //     const data = await api.put(`/customers/responsible/${customer._id}`, {
    //         "responsible": newResponsible
    //     }).then( async response => { 
    //         form.resetFields();
    //         setResponsibleFormLoad(false)
    //         setResponsibleForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Resposável atualizado!');
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)

    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // const onFinishPlan = async values => {
    //     console.log(values)
    //     setPlanFormLoad(true)
    //     const data = await api.put(`/customers/plan/${customer._id}`, {
    //         "wallet": values.wallet,
    //         "plan": values.plan,
    //     }).then( async response => { 
    //         form.resetFields();
    //         setPlanFormLoad(false)
    //         setPlanForm(false)
    //         props.setCustomer(response.data.customer)
    //         message.success('Resposável atualizado!');
    //         // Reacarrega customers
    //         const {data} = await api.get('/customers');
    //         props.setCustomers(data.customers)

    //     }).catch(error => { 
    //         console.log(error) 
    //     })
    // };

    // function toggleEditForm(){
    //     setEditForm( !editForm )
    // }

    // function toggleResponsibleForm(){
    //     setResponsibleForm( !responsibleForm)
    // }

    // function togglePlanForm(){
    //     setPlanForm( !planForm)
    // }

    const { confirm } = Modal;

    function showConfirm() {
        confirm({
            title: 'Deseja exluir o arquivo ?',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            onOk() {
                deleteFile()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function changePrice(value) {
        let v = value;
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{1})(\d{14})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{11})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2")

        setPrice("R$ " + v)
    }

    async function uploadFile(file) {
        setUploadFileLoading(true)
        const data = new FormData();
        data.append('document', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await api.post(`/customers/file/${customer._id}`, data, config)
            .then(response => {
                props.setCustomer(response.data.customer)
                message.success('Arquivo salvo');
                setUploadFileLoading(false)
                console.log(response)
            })
            .catch(err => {
                message.success('Ocorreu um erro!');
                setUploadFileLoading(false)
                console.log(err)
            })
    };

    async function deleteFile() {
        setUploadFileLoading(true)
        const data = await api.delete(`/customers/file/${customer._id}`)
            .then(response => {
                props.setCustomer(response.data.customer)
                message.success('Arquivo excluído');
                setUploadFileLoading(false)
                console.log(response)
            })
            .catch(err => {
                message.success('Ocorreu um erro!');
                setUploadFileLoading(false)
            });
    };

    function changeEdiForm() {
        changePrice(customer.price)
        setEditCustomer(!editCustomer)
    }

    // function changePhone(index, value){
    //     console.log(value)
    //     if(index == 1){
    //         if(value.replace(/\D+/g, '').trim() ){
    //             setPhone1(value)
    //         }else{
    //             setPhone1('')
    //         }
    //     }else{
    //         if(value.replace(/\D+/g, '').trim() ){
    //             setPhone2(value)
    //         }else{
    //             setPhone2('')
    //         }
    //     }
    // }

    function onChangeReadjustment(date, dateString) {
        setReadjustment(dateString)
    }

    function openFile(url) {
        window.open(url)
    }

    // const editFormContent = (
    //     <Spin tip="Atualizando..." spinning={editFormLoad}>
    //         <Form form={form} layout="vertical"  className="pop-over-edit-customer" onFinish={onFinishEdit} >

    //             <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Status é obrigatório' }]}>
    //                 <Select placeholder="Selecione um status" onChange={(e)=>setNewStatus(e)} showSearch> 
    //                     { props.statusList &&
    //                         props.statusList.map((status) => (
    //                             <Select.Option key={status.id} value={status.id}>{status.name}</Select.Option>
    //                         ))
    //                     }
    //                 </Select>
    //             </Form.Item>

    //             <Form.Item label="Data de Retorno">
    //                 <DatePicker onChange={onChangeReturn}  format="DD/MM/YYYY" placeholder="__/__/____" locale={locale} style={{ width: '40%' }} />
    //             </Form.Item>

    //             <Form.Item label="Obs">
    //                 <TextArea onChange={(e)=>setNewObs(e.target.value)} placeholder="Digite aqui alguma observação sobre o cliente..." allowClear style={{ height: '100px' }} />
    //             </Form.Item>

    //             <Button type="primary" block htmlType="submit" > Salvar Alterações</Button>  
    //         </Form>
    //     </Spin>
    // );

    // const responsibleFormContent = (
    //     <Spin tip="Atualizando..." spinning={responsibleFormLoad}>
    //         <Form form={form} layout="vertical"  className="pop-over-edit-customer" onFinish={onFinishResponsible} >
    //             <Form.Item label="Atendente">
    //                 <Select placeholder="Selecione um atendente" onChange={(e)=>setNewResponsible(e)} showSearch> 
    //                     { responsibles.map((responsible) => (
    //                         <Select.Option  value={responsible._id}>{responsible.name}</Select.Option>
    //                     ))}
    //                 </Select>
    //             </Form.Item>
    //             <Button type="primary" block htmlType="submit" > Salvar Alterações</Button>  
    //         </Form>
    //     </Spin>
    // );

    // const planFormContent = (
    //     <Spin tip="Atualizando..." spinning={planFormLoad}>
    //         <Form form={form} layout="vertical"  className="pop-over-edit-customer" onFinish={onFinishPlan} >
    //             <Form.Item label="Carteira" name="wallet" rules={[{ required: true, message: 'Carteira é obrigatório' }]}>
    //                 <Select 
    //                     onChange={(e)=>changeWallet(e)} 
    //                     placeholder="Selecione uma carteira" 
    //                     showSearch
    //                 > 
    //                     { wallets.map((wallet) => (
    //                         <Select.Option value={wallet._id}>{wallet.name}</Select.Option>
    //                     ))}
    //                 </Select>
    //             </Form.Item>
    //             <Form.Item label="Plano" name="plan" rules={[{ required: true, message: 'Plano é obrigatório' }]} >
    //                 <Select placeholder="Selecione um plano" > 
    //                     { plans.map((plan) => (
    //                         <Select.Option value={plan._id}>{plan.name}</Select.Option>
    //                     ))}
    //                 </Select>
    //             </Form.Item>
    //             <Button type="primary" block htmlType="submit" > Salvar Alterações</Button>  
    //         </Form>
    //     </Spin>
    // );

    const text = <span>Notificar retorno:</span>;
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };
    function changeReturnDateAlert(e) {
        setReturnDateAlert(e.target.value)
    };
    const content = (
        <div>
            <Radio.Group onChange={(e) => changeReturnDateAlert(e)} value={returnDateAlert}>
                <Radio style={radioStyle} value={0}>Nunca</Radio>
                <Radio style={radioStyle} value={1}>1 dia antes</Radio>
                <Radio style={radioStyle} value={2}>2 dias antes</Radio>
                <Radio style={radioStyle} value={3}>3 dias antes</Radio>
                <Radio style={radioStyle} value={4}>4 dias antes</Radio>
                <Radio style={radioStyle} value={5}>5 dias antes</Radio>
                <Radio style={radioStyle} value={6}>6 dias antes</Radio>
                <Radio style={radioStyle} value={7}>7 dias antes</Radio>
                <Radio style={radioStyle} value={8}>8 dias antes</Radio>
                <Radio style={radioStyle} value={9}>9 dias antes</Radio>
                <Radio style={radioStyle} value={10}>10 dias antes</Radio>
            </Radio.Group>
        </div>
    );

    return (
        <Modal
            visible={props.modalCustomer}
            // title={customer.company}
            onCancel={() => { props.setModalCustomer(false); setFormLoad(false); form.resetFields(); }}
            className="modal-customer"
            footer={false}
            width={1100}
            centered
            maskClosable={false}
        >
            <Form form={form} onFinish={onFinish}>
                <Spin tip="Atualizando dados do cliente..." spinning={formLoad}>
                    <div className="modal-customer-header">
                        <div className="modal-customer-header-data">
                            {!editCustomer &&
                                <p><b>Criado dia:</b> <Moment format="DD/MM/YYYY">{customer.createdAt}</Moment> </p>
                            }

                            {!editCustomer &&
                                <p><b>Código:</b> {customer.code}</p>
                            }

                            {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&

                                <p><b>Atendente:</b>{editCustomer
                                    ? <Form.Item name="responsible">
                                        <Select placeholder="Selecione um atendente" defaultValue={responsible ? responsible.name : ''} style={{ width: 150 }} showSearch>
                                            {responsibles.map((responsible) => (
                                                <Select.Option value={responsible._id}>{responsible.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    : responsible ? responsible.name : customer.responsible
                                }
                                    {!responsible && !editCustomer && <span>---</span>}
                                </p>

                            }

                            <p><b>Status:</b> {editCustomer
                                ? <Form.Item name="status">
                                    <Select placeholder="Selecione um status" defaultValue={customer.status} style={{ width: 150 }} showSearch>
                                        {props.statusList &&
                                            props.statusList.map((status) => (
                                                <Select.Option key={status.id} value={status.id} select={true}>{status.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                : <Tag className={`tag ${customer.status}`}><StatusMask status={customer.status} /></Tag>}
                            </p>

                            <p><b>Prioridade:</b> {editCustomer
                                ? <Form.Item name="priority">
                                    <Select placeholder="Selecione" defaultValue={customer.priority} style={{ width: 100 }} showSearch>
                                        <Select.Option value="high">Alta</Select.Option>
                                        <Select.Option value="medium" select={true}>Media</Select.Option>
                                        <Select.Option value="low">Baixa</Select.Option>
                                    </Select>
                                </Form.Item>
                                : <Priority priority={customer.priority} text />}
                            </p>

                            <p><b>Data de Retorno:</b>{editCustomer
                                ? <>
                                    <Form.Item name="returnDate">
                                        <DatePicker
                                            onChange={onChangeReturn}
                                            className="data-retorno"
                                            format="DD/MM/YYYY HH:mm"
                                            showTime
                                            placeholder="__/__/____"
                                            locale={locale}
                                            defaultValue={customer.returnDate ? moment(customer.returnDate, "DD/MM/YYYY HH:mm") : ''}
                                        />
                                    </Form.Item>
                                    <Popover placement="bottomRight" title={text} content={content} trigger="click">
                                        <Tag className="icon-notification"><BiBell size="15px" />  </Tag>
                                    </Popover>
                                </>
                                : customer.returnDate
                            }

                                {!customer.returnDate && !editCustomer && <span>---</span>}
                            </p>
                        </div>
                        <div className="modal-customer-header-actions" style={{ display: 'flex' }}>
                            <Button type="dashed" style={{ display: 'flex' }} onClick={(e) => changeEdiForm()}>
                                {!editCustomer
                                    ? <span>Editar Cliente</span>
                                    : <span>Cancelar</span>
                                }
                            </Button>
                            {editCustomer && <Button type="primary" style={{ marginLeft: '15px' }} className="green" htmlType="submit">Salvar Alterações</Button>}
                        </div>
                    </div>
                    <Row className="modal-customer-body">
                        <Col span={8} className="col-resume">
                            <h3>Dados do cliente:</h3>

                            <p> <b>Responsável:</b> {editCustomer ? <Form.Item name="name"><Input defaultValue={customer.name} name="customer-name" /></Form.Item> : customer.name} </p>

                            <p><b>Empresa:</b> {editCustomer ? <Form.Item name="company"><Input defaultValue={customer.company} /></Form.Item> : customer.company}</p>

                            <p><b>Cnpj:</b> {editCustomer ? <Form.Item><MaskedInput mask="11.111.111/1111-11" placeholder='__.___.___/____-__' value={customer.document} /></Form.Item> : <FormatDocument cnpj={customer.document} />}</p>

                            <p><b>Email:</b> {editCustomer ? <Form.Item name="email"><Input defaultValue={customer.email} /></Form.Item> : customer.email}</p>

                            <p><b>Local:</b> {editCustomer
                                ? <div style={{ display: 'flex' }}>
                                    <Form.Item className="city" name="city" >
                                        <Select
                                            showSearch
                                            placeholder="Cidade"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: '180px' }}
                                            defaultValue={customer.city}
                                            className="edit-city"
                                        >
                                            {cities.map((city) => (
                                                <Select.Option value={city.Nome}>{city.Nome}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item className="state" name="state" >
                                        <Select
                                            showSearch
                                            placeholder="Estado"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: '80px' }}
                                            defaultValue={customer.state}
                                            className="edit-state"
                                        >
                                            {states.map((state) => (
                                                <Select.Option value={state.Sigla}>{state.Sigla}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                : <> {customer.city} - {customer.state} </>
                            }</p>

                            <p><b>Telefone 1:</b>
                                {contact && contact[0] && !editCustomer && <FormatPhone number={contact[0].number} />}
                                {editCustomer && <Form.Item name="phone1"><MaskedInput mask="(11) 11111-1111" placeholder="(__) - ____-____" defaultValue={contact[0].number} /></Form.Item>}
                            </p>

                            <p><b>Telefone 2:</b>
                                {contact && contact[1] && !editCustomer && <FormatPhone number={contact[1].number} />}
                                {editCustomer && <Form.Item name="phone2"><MaskedInput mask="(11) 11111-1111" placeholder="(__) - ____-____" defaultValue={contact[1].number} /></Form.Item>}
                            </p>

                            {/*                         
                            { contact && contact.map((e, index) => (
                                <>
                                {e.number != "" && 
                                    <p><b>{e.name}:</b> {editCustomer ?  <Form.Item><MaskedInput  mask="(11) 11111-1111" placeholder="(__) - ____-____" value={e.number.trim()}  onChange={(e)=>changePhone(index, e.target.value + 1)} /></Form.Item> : <FormatPhone number={e.number} /> }</p>
                                }
                                </>
                            ))} */}
                        </Col>
                        <Col span={8} className="col-plan">
                            <h3>Carteira e Plano:</h3>

                            {wallet && !editCustomer &&
                                <img src={wallet.logo} style={{ borderRadius: "5px 5px 0 0 " }} width="250px" />
                            }

                            <Card style={{ width: 250 }} >
                                {editCustomer &&
                                    <p><b>Carteira:</b>
                                        <Form.Item name="wallet">
                                            <Select
                                                onChange={(e) => changeWallet(e)}
                                                placeholder="Selecione uma carteira"
                                                showSearch
                                                defaultValue={wallet ? wallet._id : ''}
                                            >
                                                {wallets.map((wallet) => (
                                                    <Select.Option value={wallet._id}>{wallet.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </p>
                                }

                                <p><b>Plano:</b> {editCustomer ? <Form.Item name="plan"><Input defaultValue={customer.plan} /></Form.Item> : <span style={{ fontSize: '12px' }}>{customer.plan}</span>}</p>

                                <p><b>Valor mensal:</b> {editCustomer ? <Form.Item><Input value={price} onChange={(e) => changePrice(e.target.value)} /></Form.Item> : <MonetaryConverter quantity={customer.price} class="plan-price" />}</p>

                                <p><b>Reajuste:</b> {editCustomer
                                    ? <Form.Item name="readjustment">
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            placeholder="__/__/____"
                                            locale={locale}
                                            style={{ width: '100%' }, { borderRadius: '8px' }}
                                            //value={customer.readjustment}
                                            onChange={onChangeReadjustment}
                                            defaultValue={customer.readjustment ? moment(customer.readjustment, "DD/MM/YYYY") : ''}
                                        />
                                    </Form.Item>
                                    : customer.readjustment
                                }</p>

                                <p><b>Idades:</b> {editCustomer ? <Form.Item name="ages"><Input defaultValue={customer.ages} /></Form.Item> : customer.ages}</p>

                                <p><b>Vidas:</b></p>
                                <p className="lives-line">
                                    {editCustomer ? <Form.Item name="lives"><Input style={{ width: '50%' }} defaultValue={customer.lives} /></Form.Item> : customer.lives}

                                    {uploadFileLoading &&
                                        <LoadingOutlined style={{ fontSize: '12px' }, { marginLeft: '10px' }} spin />
                                    }

                                    {customer.files && customer.files.length > 0 && customer.files[0].type == 'lives' && !uploadFileLoading &&
                                        <div className="buttons">
                                            <Tooltip placement="top" title="Baixar arquivo">
                                                <Button className="excel" size="small"><FileExcelOutlined size="20px" onClick={() => openFile(customer.files[0].url)} /></Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title="Excluir arquivo">
                                                <Button className="delete-file" size="small" danger onClick={() => showConfirm()} >x</Button>
                                            </Tooltip>
                                        </div>
                                    }

                                    {customer.files && customer.files.length == 0 && !uploadFileLoading &&
                                        <Upload
                                            action={uploadFile}
                                            showUploadList={false}
                                        >
                                            <Tooltip placement="top" title="Adicionar arquivo">
                                                <Button size="small"><FileAddOutlined size="20px" /></Button>
                                            </Tooltip>
                                        </Upload>
                                    }
                                </p>
                            </Card>

                        </Col>
                        <Col span={8} className="col-historic">
                            {!editCustomer
                                ? <>
                                    <h3>Histórico:</h3>
                                    <br />
                                    {historic && historic.map((item, index) => (
                                        <Timeline >
                                            <Timeline.Item color="blue">
                                                <p className="date"><Moment format="DD/MM/YYYY hh:mm">{item.updatedAt}</Moment></p>
                                                <div className="speech-bubble">
                                                    {index == 0 && <p className="title"><b>{item.updatedBy}</b> criou o cliente.</p>}
                                                    {index > 0 && <p className="title">{ReactHtmlParser(item.message)}</p>}
                                                    {item.obs &&
                                                        <p className="obs"><small><b>Obs:</b> {item.obs}</small></p>
                                                    }
                                                </div>
                                            </Timeline.Item>
                                        </Timeline>
                                    ))}
                                </>
                                : <p><b>Observações:</b><Form.Item name="obs"><TextArea className="obs" placeholder="Digite aqui alguma observação sobre o cliente..." allowClear /></Form.Item></p>
                            }
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </Modal>
    )
}

export default EditCustomer;