import React, { useState } from 'react';
import { Form, Input, Button, Modal, message, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';

import { BiPlusCircle } from "react-icons/bi";
import api from '../../../api';

import  './add-wallet.css';

function AddCustomer(props){

    const [modalNewWallet, setModalNewWallet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [base64, setBase64] = useState('')

    const onFinish = async values => {
        setLoading(true)

        let newWallet = {
            name: values.name,
            slug: values.name.slugify(),
            logo: base64
        }
        
        const data = await api.post('/wallets', newWallet).then( async response => { 
            const {data} = await api.get('/wallets');
            props.setWallets(data)
            setModalNewWallet(false)
            setLoading(false)
            setBase64('')
            message.success('Carteira adicionada com suceesso!');
        }).catch(error => { 
            console.log(error) 
            setLoading(false)
            message.error('Erro ao adicionar carteira.');
        })
    };
    
    
    return(
        <div>

            <div className="add-wallet" onClick={()=>setModalNewWallet(true)}>
                <BiPlusCircle size="30px" className="icon"/>
                <span>Adicionar Carteira</span>
            </div>
            
            <Modal
                visible={modalNewWallet}
                title="Adicionar Carteira"
                onCancel={()=>setModalNewWallet(false)}
                footer={false}
                centered
                width={800}
            >
            
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={onFinish}
                    >
                        <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'O nome é obrigatório' }]} >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Logo">
                            <ImgCrop modalTitle="Editar Imagem" aspect={300/150}>
                                <Upload
                                    listType="picture-card"
                                    beforeUpload={file => {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = function () {
                                            setBase64(reader.result)
                                        };
                                        reader.onerror = function (error) {
                                            console.log('Error: ', error);
                                        };       
                                    return false;
                                }}
                                >
                                    {base64 
                                        ? <img src={base64} width="100%"></img>
                                        : 'Selecionar Imagem'
                                    }  
                                </Upload>
                            </ImgCrop>
                        </Form.Item>

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" loading={loading} style={{width: "200px"}}>Adicionar Carteira</Button>
                        </div>

                    </Form>
                </div>
               
            </Modal>
        </div>
    )       
}

export default AddCustomer;