import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, Steps, InputNumber, DatePicker, Empty, message, Spin, Progress, Tag, Result } from 'antd';
import MaskedInput from 'antd-mask-input'
import readXlsxFile from 'read-excel-file'
import * as moment from 'moment'

import { BiPlusCircle, BiUpload } from "react-icons/bi";

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import api from '../../../api';
import cities from '../../../helpers/locales/cities.json';
import states from '../../../helpers/locales/states.json';
import { Context } from '../../../context/AuthContext';
import './add-customer.css';

function AddGoal(props) {
    const { userData } = useContext(Context);
    const [modalNewGoal, setModalNewGoal] = useState(false);

    const [modalImportCustomer, setModalImportCustomer] = useState(false)
    const [loadImport, setLoadImport] = useState(true)
    const [progress, setProgress] = useState(0)
    const [customersImport, setCurtomersImport] = useState([])
    const [customersImported, setCustomersImport] = useState([])
    const [time, setTime] = useState(0)
    const [totalImported, setTotalImported] = useState(0)

    const [step, setStep] = useState(0);
    const [wallets, setWallets] = useState([])
    const [plans, setPLans] = useState([])

    const [responsibles, setResponsibles] = useState([]);
    const [loadingPlans, setLoadingPlans] = useState(false)
    const [emptyPlans, setEmptyPlans] = useState(true)
    const [addGoalLoading, setAddGoalLoading] = useState(false)

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [company, setCompany] = useState('');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    const [wallet, setWallet] = useState('');
    const [plan, setPLan] = useState('');
    const [price, setPrice] = useState('')
    const [lives, setLives] = useState('');
    const [ages, setAges] = useState('');
    const [readjustment, setReadjustment] = useState('');

    const [responsible, setResponsible] = useState('');
    const [obs, setObs] = useState('');

    const { Step } = Steps;
    const { TextArea } = Input;
    const { Option } = Select;

    // Step 1
    const step1OnFinish = values => {
        let goal = {
            responsible: userData.roles.includes('admin') ? values.responsible : "",
            company: values.company,
            weekDay: values.weekDay,
            interestDate: readjustment,
            priority: values.priority,
            proposal: values.proposal === "sim" ? true : false,
            origin: values.origin,
            sell: values.sell,
            values: price.replace("R$ ", '').replace(/\./g, '').replace(',', '.'),
            status: values.status,
            obs: values.obs
        }

        createGoal(goal);
    };

    function onChangeReadjustment(date, dateString) {
        setReadjustment(dateString)
    }

    function changePrice(value) {
        let v = value;
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{1})(\d{14})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{11})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2")
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2")

        setPrice("R$ " + v)
    }

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);

    async function createGoal(goal) {
        setAddGoalLoading(true);
        await api.post('/goals', goal).then(async response => {
            const { data } = await api.get('/goals');
            props.setGoals(data.goals)
            // Recarrega totais
            //const statusTotal = await api.get('/goals/totals/status');
            //props.setStatusTotal(statusTotal.data);
            setModalNewGoal(false)
            setAddGoalLoading(false)
            message.success('Nova meta criada com suceesso!');
        }).catch(error => {
            console.log(error)
            setAddGoalLoading(false)
            message.error('Erro ao criar nova meta.');
        })
    }

    return (
        <div>
            <div className="add-buttons">
                <div className="add-customer" onClick={() => setModalNewGoal(true)} style={{ width: `100%` }}>
                    <BiPlusCircle size="20px" />
                    <span>Criar nova meta de gestão</span>
                </div>
            </div>

            <Modal
                visible={modalNewGoal}
                title="Criar nova meta de gestão"
                onCancel={() => setModalNewGoal(false)}
                footer={false}
                centered
                width={800}
                maskClosable={false}
            >
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={step1OnFinish}
                    >
                        <Form.Item label="Prioridade" name="priority" rules={[{ required: true, message: 'Empresa é obrigatório' }]}>
                            <Select placeholder="Selecione" style={{ width: 150 }}>
                                <Select.Option value="high">Alta</Select.Option>
                                <Select.Option value="medium">Media</Select.Option>
                                <Select.Option value="low">Baixa</Select.Option>
                            </Select>
                        </Form.Item>

                        {userData.roles.includes('admin') &&
                            <Form.Item label="Vendedor">
                                <Select onChange={(e) => setResponsible(e)} placeholder="Selecione um(a) atendende" showSearch>
                                    {responsibles.map((responsible) => (
                                        <Option value={responsible._id}>{responsible.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item label="Empresa" name="company" rules={[{ required: true, message: 'Empresa é obrigatório' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Dia da semana" className="city" name="weekDay" rules={[{ required: true, message: 'Dia da semana é obrigatório' }]} >
                            <Select
                                placeholder="Selecione o dia da semana"
                                style={{ width: '60%' }}
                            >
                                <Select.Option value="dom">Domingo</Select.Option>
                                <Select.Option value="seg">Segunda-feira</Select.Option>
                                <Select.Option value="ter">Terça-feira</Select.Option>
                                <Select.Option value="qua">Quarta-feira</Select.Option>
                                <Select.Option value="qui">Quinta-feira</Select.Option>
                                <Select.Option value="sex">Sexta-feira</Select.Option>
                                <Select.Option value="sab">Sábado</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Data do interesse:" name="interestDate" rules={[{ required: true, message: 'Data do interesse é obrigatório' }]} >
                            <DatePicker
                                format="DD/MM/YYYY"
                                placeholder="__/__/____"
                                locale={locale}
                                style={{ width: '100%' }}
                                onChange={onChangeReadjustment}
                                name="readjustment" style={{ width: '30%' }}
                            />
                        </Form.Item>

                        <Form.Item label="Proposta" className="city" name="proposal" rules={[{ required: true, message: 'Proposta é obrigatório' }]} >
                            <Select
                                placeholder="Selecione"
                                style={{ width: '60%' }}
                            >
                                <Option value="sim">Sim</Option>
                                <Option value="nao">Não</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Origem Listagem" name="origin" rules={[{ required: true, message: 'Origem Listagem é obrigatório' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Vender" name="sell" rules={[{ required: true, message: 'Vender é obrigatório' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Valores" rules={[{ required: true, message: 'Valores é obrigatório' }]}>
                            <Input value={price} onChange={(e) => changePrice(e.target.value)} style={{ width: '50%' }} />
                        </Form.Item>

                        <Form.Item label="Status" className="city" name="status" rules={[{ required: true, message: 'Status é obrigatório' }]} >
                            <Select
                                placeholder="Selecione"
                                style={{ width: '60%' }}
                            >
                                <Option value="waiting">Aguardando</Option>
                                <Option value="analysis">Em análise</Option>
                                <Option value="return">Retorno</Option>
                                <Option value="changing">Em troca</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Observações" name="obs">
                            <TextArea placeholder="Digite aqui alguma observação sobre a meta..." allowClear style={{ height: '100px' }} />
                        </Form.Item>

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" loading={addGoalLoading}>Criar nova meta</Button>
                        </div>

                    </Form>
                </div>


            </Modal>


        </div>
    )
}

export default AddGoal;