import React from 'react';
import Numeral from "numeral";
import "numeral/locales/pt-br";


function monetaryConverter(props){
    Numeral.locale('pt-br');
    const value = props.quantity;
    const converted = Numeral(Number(value)).format('$0,0.00')
    return(
        <span className={props.class}>{converted}</span>
    )
}

export default monetaryConverter;