import React, { useState, useContext } from 'react';
import { Row, Col, Modal, Button, Form, Input, Spin, Upload, message, Switch  } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Context } from '../../../context/AuthContext';
import { EditOutlined } from '@ant-design/icons';

import api from '../../../api';

import ListPlans from './listPlans';
import AddPlan from './addPlan';

import './edit-wallet.css';

function EditWallet(props){

    const { userData } = useContext(Context);
    const { wallet, selectWallet, plans, setPlans, loadPlans } = props;
    const [loading, setLoading] = useState(false);
    const [base64, setBase64] = useState('')
    const [editForm, setEditForm] = useState(false) 

    const onFinish = async values => {
        console.log(values)
        setLoading(true)

        let newWallet = {
            active: true,
            name: values.name,
            logo: base64
        }
        
        const data = await api.put(`/wallets/${wallet._id}`, newWallet).then( async response => { 
            const {data} = await api.get('/wallets');
            props.setWallets(data)
            setEditForm(false)
            setLoading(false)
            props.setModalWallet(false) 
            setBase64('')
            message.success('Carteira editada com suceesso!');
        }).catch(error => { 
            console.log(error) 
            setLoading(false)
            message.error('Erro ao editar carteira.');
        })
    };

    return(
        <Modal
            visible={props.modalWallet}
            onCancel={()=>{ props.setModalWallet(false) } }
            className="modal-wallet"
            title={wallet.name}
            footer={false}
            width={1000}
            centered
        >
            <Row className="modal-plan-body">
                { !editForm &&
                    <>
                    <Col span={6} >
                        <div className="wallet-info">
                            <img src={wallet.logo}  width="100%"></img>
                            { userData.roles.includes('admin') &&
                                <Button type="dashed" icon={<EditOutlined />} onClick={()=>setEditForm(true)} >Editar Carteira</Button>
                            }
                        </div>            
                    </Col>
                    <Col span={18} >
                        <div className="plan-list">
                        {loadPlans
                            ?   <Spin tip="Carregando Planos..."><div className="loading-plans"></div></Spin>
                            :   <div className="list-plans">
                                    <h2>Planos:</h2>
                                    { userData.roles.includes('admin') && (<AddPlan wallet={wallet} setPlans={setPlans} /> )} 
                                    <ListPlans plans={plans} setPlans={setPlans} />
                                </div>
                        }
                        </div>
                    </Col>
                    </>
                }

                { editForm &&
                    <Form
                        layout="horizontal"
                        onFinish={onFinish}
                        className="edit-form"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item label="Carteira ativa?"  >
                            <Switch defaultChecked  checkedChildren="Sim" unCheckedChildren="Não" />
                        </Form.Item>

                        <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'O nome é obrigatório' }]} >
                            <Input placeholder={wallet.name} />
                        </Form.Item>

                        <Form.Item label="Logo">
                            <ImgCrop modalTitle="Editar Imagem" aspect={300/150}>
                                <Upload
                                    listType="picture-card"
                                    beforeUpload={file => {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = function () {
                                            setBase64(reader.result)
                                        };
                                        reader.onerror = function (error) {
                                            console.log('Error: ', error);
                                        };       
                                    return false;
                                }}
                                >
                                    {base64 
                                        ? <img src={base64} width="100%"></img>
                                        : <img src={wallet.logo} width="100%"></img>
                                    }  
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                                
                        <div className="add-customer-form-footer">
                            <Button type="default" onClick={ ()=>setEditForm(false) }  >Cancelar</Button>
                            <Button className="green" type="primary" htmlType="submit" loading={loading}style={{width: '200px'}}>Salvar Alterações</Button>                            
                        </div>

                    </Form>
                }
            </Row>
        </Modal>
    )
}

export default EditWallet;