import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, Steps, InputNumber, DatePicker, Empty, message, Spin, Progress, Tag, Result} from 'antd';
import MaskedInput from 'antd-mask-input'
import readXlsxFile from 'read-excel-file'
import * as moment from 'moment'

import { BiPlusCircle, BiUpload } from "react-icons/bi";

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import api from '../../../api';
import cities from '../../../helpers/locales/cities.json';
import states from '../../../helpers/locales/states.json';

import  './add-customer.css';

function AddCustomer(props){
    const [modalNewCustomer, setModalNewCustomer] = useState(false);

    const [modalImportCustomer, setModalImportCustomer] = useState(false)
    const [loadImport, setLoadImport] = useState(true)
    const [progress, setProgress] = useState(0)
    const [customersImport, setCurtomersImport] = useState([])
    const [customersImported, setCustomersImport] = useState([])
    const [time, setTime] = useState(0)
    const [totalImported, setTotalImported] = useState(0)

    const [step, setStep] = useState(0);
    const [wallets, setWallets] = useState([])
    const [plans, setPLans] = useState([])
    
    const [responsibles, setResponsibles] = useState([]);
    const [loadingPlans, setLoadingPlans] = useState(false)
    const [emptyPlans, setEmptyPlans] = useState(true)
    const [addCustomerLoading, setAddCustomerLoading] = useState(false)

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [company, setCompany] = useState('');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    const [wallet, setWallet] = useState('');
    const [plan, setPLan] = useState('');
    const [price, setPrice] = useState('')
    const [lives, setLives] = useState('');
    const [ages, setAges] = useState('');
    const [readjustment, setReadjustment] = useState('');

    const [responsible, setResponsible] = useState('');
    const [obs, setObs] = useState('');

    const { Step } = Steps;
    const { TextArea } = Input;
    const { Option } = Select;

    // Step 1
    const step1OnFinish = values => {
        setCode(values.code)
        setName(values.name)
        setCompany(values.company)
        setDocument(values.document.replace(/\D+/g, ''))
        setEmail(values.email)
        setCity(values.city)
        setState(values.state)
        setPhone1(values.phone1.replace(/\D+/g, ''))
        console.log(values)
        if(values.phone2) setPhone2(values.phone2.replace(/\D+/g, ''))

        setStep(1)
    };
    
    const step2OnFinish = values => {
        setWallet(values.wallet)
        setPLan(values.plan)

        setLives(values.lives)
        setAges(values.ages)
        setStep(2)
    };

    const step3OnFinish = values => {        
        setResponsible(values.responsible)
        setObs(values.obs)
        createUser()
    };

    function onChangeReadjustment(date, dateString) {
        setReadjustment(dateString)
    }

    function changePrice( value ){
        let v = value;
            v=v.replace(/\D/g,"")  
            v=v.replace(/(\d{1})(\d{14})$/,"$1.$2") 
            v=v.replace(/(\d{1})(\d{11})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{8})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{5})$/,"$1.$2")  
            v=v.replace(/(\d{1})(\d{1,2})$/,"$1,$2")

        setPrice( "R$ "+v )
    }

    useEffect( () => {
        (async () => {
            const {data} = await api.get('/wallets');
            setWallets(data);
        })();
    }, []);

    async function changeWallet(walletId){
        setLoadingPlans(true)
        setWallet(walletId)

        const {data} = await api.get(`/plans/wallet/active/${walletId}`);
        setLoadingPlans(false)
        if(data.length > 0){
            setPLans(data);        
            setEmptyPlans(false)
        }else{
            setEmptyPlans(true)
        }
        
    }

    useEffect( () => {
        (async () => {
            const {data} = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);
    

    async function createUser(){
        setAddCustomerLoading(true)
        let newCustomer = {
            code: code,
            name: name,
            company: company,
            document: document,
            email: email,
            city: city,
            state: state,
            lives: lives.toString(),
            ages: ages,
            wallet: wallet,
            plan: plan,         
            price:  price.replace("R$ ",'').replace(/\./g,'').replace(',','.'), 
            contact: [
                { 
                    name: "Telefone 1",
                    number: phone1.replace(/\D+/g, '').trim()
                },
                { 
                    name: "Telefone 2",
                    number: phone2.replace(/\D+/g, '').trim()
                }
            ],
            readjustment: readjustment,
            historic: [
                {
                    status: "created",
                    obs: obs
                }
            ]
        }
        
        if(responsible) newCustomer.responsible = responsible
        if(obs) newCustomer.obs =obs 

        const data = await api.post('/customers', newCustomer).then( async response => { 
            const {data} = await api.get('/customers');
            props.setCustomers(data.customers)
            // Recarrega totais
            const statusTotal = await api.get('/customers/totals/status');
            props.setStatusTotal(statusTotal.data);
            setModalNewCustomer(false)
            setAddCustomerLoading(false)
            message.success('Cliente criado com suceesso!');
            setStep(0)
        }).catch(error => { 
            console.log(error) 
            setAddCustomerLoading(false)
            message.error('Erro ao criar novo cliente.');
        })
    }

    async function onChangeUpload(event){
        setLoadImport(true)
        setModalImportCustomer(true)
        var customers = []
        var file = event.target.files[0]
        const rows = await readXlsxFile(file)
        if(rows.length > 150){
            message.error("Sua planilha passou do limite de 150 linhas.")
            setModalImportCustomer(false)
        }else{
            let time = (2 * rows.length) 
            setTime( formatahhmmss(time) ) 
            setTotalImported(rows.length - 1)
            for(var i=0; i < rows.length; i++){
                if(i > 0){
                    // Aumenta progress da barra
                    setProgress(  Math.round( ( i / rows.length ) * 100.01)   )
                    // Busca pelo Plano     
                    //console.log(rows[i][6])
                    let wallet =  await api.get(`/wallets/${rows[i][6]?rows[i][6].slugify():'' }`);   
                    // Busca pelo reponsável
                    //let responsible = await api.get(`/users/find/name?value=${rows[i][8]}`);                    
                    // Divide cidade e estado
                    let locale = rows[i][3].split('-')
                    // Divide telefones
                    let phones = rows[i][12].split(';')
                    //  Formata o preço 
                    let price = rows[i][8]?String(rows[i][8]):''
                    
                    let code = rows[i][2]?rows[i][2]:''
                    let company = rows[i][0]?rows[i][0]:''
                    let document = rows[i][1]?rows[i][1]:''
                    let name = rows[i][9]?rows[i][9]:''
                    let email = rows[i][11]?rows[i][11]:''
                    let city = locale[0]?locale[0].trim():''
                    let state = locale[1]?locale[1].trim():''
                    let lives = rows[i][5]?rows[i][5]:''
                    let ages = rows[i][4]?rows[i][4]:''
                    let obs = rows[i][13]?rows[i][13]:''
                    let readjustment = rows[i][10]?moment(rows[i][10]).format('DD/MM/YYYY'):''
                    let plan = rows[i][7]?rows[i][7]:''

                    let contact = [
                        {  name: "Telefone 1", number: phones[0].replace(/\D+/g, '').trim() },
                        {  name: "Telefone 2", number: phones[1]?phones[1].replace(/\D+/g, '').trim():''  }
                    ]

                    let historic = [{
                        status: "created",
                        obs: rows[i][13]?rows[i][13]:''
                    }]
                
                    // Objeto do novo cliente
                    let newCustomer = {
                        code,
                        company,
                        document: String(document),
                        name,
                        email,
                        city,
                        state,
                        lives,
                        ages,
                        obs, 
                        contact,
                        readjustment,
                        historic,
                        plan,
                        price
                    }
                    
                    //console.log(newCustomer)
                    
                    // // Se o responsável existir
                    // if(responsible.data._id){
                    //     newCustomer.responsible= responsible.data._id 
                    // }
                    
                    // // Se o plano existir 
                    // if(plan.data._id){
                    //     newCustomer.wallet= plan.data.wallet
                    //     newCustomer.plan= plan.data._id
                    // }   

                    // Se a carteira existir
                    if(wallet.data._id){
                        newCustomer.wallet= wallet.data._id 
                    }

                    // Cliente Row
                    let customer = {
                        document: rows[i][0],
                        company: rows[i][1]
                    }

                    // Tenta adicionar no banco de dados
                    let data = await api.post('/customers', newCustomer).then( async response => { 
                        customer.status = 'ok'
                    }).catch(error => { 
                        console.log(error)
                        customer.status = 'error'
                        customer.error = 'Cnpj já cadstrado.'
                    })
                    customers.push(customer)
                    // Tempo de espera (1 segundo)
                    await new Promise(r => setTimeout(r, 1000));
                }
            }
            // Chama clientes de novo 
            const {data} = await api.get('/customers');
            props.setCustomers(data.customers)
            // Recarrega totais
            const statusTotal = await api.get('/customers/totals/status');
            props.setStatusTotal(statusTotal.data);
            // Atualiza Modal
            setCustomersImport(customers)
            setLoadImport(false)
        }
    } 
    
    function formatahhmmss(s){
        function duas_casas(numero){
          if (numero <= 9){
            numero = "0"+numero;
          }
          return numero;
        }
        var hora = duas_casas(Math.round(s/3600));
        var minuto = duas_casas(Math.round((s%3600)/60));
        var segundo = duas_casas((s%3600)%60);
        //var formatado = hora+":"+minuto+":"+segundo;
        var formatado = minuto+":"+segundo;
        return formatado;
    }
    
    return(
        <div>
            <div className="add-buttons">
                <div className="add-customer" onClick={()=>setModalNewCustomer(true)}>
                    <BiPlusCircle size="20px" />
                    <span>Criar novo cliente</span>
                </div> 
                <label className="import-customer" htmlFor="upload-button">
                    <BiUpload size="20px" />
                    <span>Importar clientes</span>
                </label> 
                <input id="upload-button" type="file" name="file" hidden onChange={onChangeUpload} />
            </div>
            
            <Modal
                visible={modalNewCustomer}
                title="Criar novo cliente"
                onCancel={()=>setModalNewCustomer(false)}
                footer={false}
                centered
                width={800}
                maskClosable={false}
            >
                <Steps size="small" current={step}>
                    <Step title="Dados do Cliente" />
                    <Step title="Dados do Plano" />
                    <Step title="Responsável" />
                </Steps>    

                <br />
                { step === 0 &&(
                    <div className="add-customer-step-1">
                        <Form
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            onFinish={step1OnFinish}
                        >
                            <Form.Item label="Código" name="code" rules={[{ required: true, message: 'Código é obrigatório' }]} >
                                <Input style={{ width: '35%' }} />
                            </Form.Item>
                            
                            <Form.Item label="Responsável" name="name" rules={[{ required: true, message: 'Responsável é obrigatório' }]}>
                                <Input  />
                            </Form.Item>

                            <Form.Item label="Empresa" name="company" rules={[{ required: true, message: 'Empresa é obrigatório' }]}>
                                <Input  />
                            </Form.Item>

                            <Form.Item label="Cnpj" name="document" rules={[{ required: true, message: 'Cnpj é obrigatório' }]}>
                                <MaskedInput  mask="11.111.111/1111-11" placeholder='__.___.___/____-__' name="document" />
                            </Form.Item>

                            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email é obrigatório' }]}>
                                <Input  placeholder="seu@email.com.br" name="email" />
                            </Form.Item>
                            
                            
                            <Form.Item label="Cidade" className="city" name="city" rules={[{ required: true, message: 'Cidade é obrigatório' }]} >
                                <Select  
                                    showSearch
                                    placeholder="Cidade" 
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    name="local"
                                    style={{ width: '60%' }} 
                                > 
                                    { cities.map((city) => (
                                        <Option value={city.Nome}>{city.Nome}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Estado" className="state" name="state" rules={[{ required: true, message: 'Estado é obrigatório' }]} >
                                <Select  
                                    showSearch
                                    placeholder="Estado" 
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    name="local"
                                    style={{ width: '40%' }} 
                                > 
                                    { states.map((state) => (
                                        <Option value={state.Sigla}>{state.Sigla}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        
                        
                            <Form.Item label="Telefone 1:" name="phone1" rules={[{ required: true, message: 'Telefone 1 é obrigatório' }]} >
                                <MaskedInput  mask="(11) 11111-1111" placeholder="(__) - ____-____"  name="phone1" />
                            </Form.Item>

                            <Form.Item label="Telefone 2:">
                                <MaskedInput  mask="(11) 11111-1111" placeholder="(__) - ____-____" name="phone2" />
                            </Form.Item>

                            <div className="add-customer-form-footer">
                                <Button type="primary" htmlType="submit">Avançar</Button>
                            </div>

                        </Form>
                    </div>
                )}

                { step === 1 &&(
                    <div className="add-customer-step-2">
                        <Form
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            onFinish={step2OnFinish}
                        >
                            <Form.Item label="Carteira" name="wallet" rules={[{ required: true, message: 'Carteira é obrigatório' }]}>
                                <Select 
                                    onChange={(e)=>changeWallet(e)} 
                                    placeholder="Selecione uma carteira" 
                                    showSearch
                                > 
                                    { wallets.map((wallet) => (
                                        <Option value={wallet._id}>{wallet.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Plano" name="plan" rules={[{ required: true, message: 'Plano é obrigatório' }]} >
                                {/* <Select placeholder="Selecione um plano" loading={loadingPlans} disabled={emptyPlans}> 
                                    { plans.map((plan) => (
                                        <Option value={plan._id}>{plan.name}</Option>
                                    ))}
                                </Select> */}
                                <Input />
                            </Form.Item>

                            <Form.Item label="Valor Mensal" rules={[{ required: true, message: 'Valor Mensal é obrigatório' }]}>
                                <Input value={price}  onChange={(e)=>changePrice(e.target.value)} style={{ width: '50%' }}/>
                            </Form.Item>

                            <Form.Item label="Vidas:" name="lives" rules={[{ required: true, message: 'Vidas é obrigatório' }]}>
                                <InputNumber name="lives"  min={1} style={{ width: '25%' }}/>
                            </Form.Item>

                            <Form.Item label="Idades:" name="ages" rules={[{ required: true, message: 'Idades é obrigatório' }]}>
                                <Input placeholder="" name="ages" style={{ width: '80%' }} />
                            </Form.Item>

                            <Form.Item label="Reajuste:" name="readjustment" rules={[{ required: true, message: 'Reajuste é obrigatório' }]} >
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    placeholder="__/__/____" 
                                    locale={locale} 
                                    style={{ width: '100%' }} 
                                    onChange={onChangeReadjustment}  
                                    name="readjustment" style={{ width: '30%' }} 
                                />
                            </Form.Item>

                            <div className="add-customer-form-footer">
                                {/* <Button type="default" onClick={()=>setStep(0)}>Voltar</Button> */}
                                <Button type="primary" htmlType="submit">Avançar</Button>
                            </div>
                        
                        </Form>
                    </div> 
                )}
                
                { step === 2 &&(
                    <div className="add-customer-step-3">
                        <Form
                            labelCol={{ span: 6}}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            onFinish={step3OnFinish}
                        >
                            <Form.Item label="Atendente">
                                <Select onChange={(e)=>setResponsible(e)} placeholder="Selecione um(a) atendende" showSearch> 
                                    { responsibles.map((responsible) => (
                                        <Option value={responsible._id}>{responsible.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Observações">
                                <TextArea  onChange={(e)=>setObs(e.target.value)} placeholder="Digite aqui alguma observação sobre o cliente..." allowClear style={{ height: '100px' }} />
                            </Form.Item>

                            <div className="add-customer-form-footer">
                                {/* <Button type="default" onClick={()=>setStep(1)}>Voltar</Button> */}
                                <Button className="green" type="primary" htmlType="submit" loading={addCustomerLoading}>Finalizar</Button>
                            </div>
                        </Form>
                    </div> 
                )}

            </Modal>



            <Modal
                visible={modalImportCustomer}
                onCancel={()=>setModalImportCustomer(false)}
                keyboard={false}
                footer={false}
                centered
                width={1000}
                maskClosable={false}
                className="modalImportCustomer"
                className={`modalImportCustomer ${ !loadImport ? 'imported' : 'loading'}`}
            >
                {loadImport &&
                    <div className="import-loading">
                        <h3>Aguarde !</h3>
                        <Progress percent={progress} status="active" className="loading" />
                        <p>Estamos processando sua planilha <br/> e importando os clientes. <br/> <small>Tempo estimado {time}</small></p>
                    </div>                     
                }
                    
                {!loadImport &&
                    <div className="imported-customers">
                    
                        <Result
                            status="success"
                            title="Importação finalizada!"
                            subTitle={`Confira abaixo os clientes ${totalImported} importados para o sistema.`}
                            className="title"
                        />

                        <div className="item header">
                            <div className="data">
                                <span className="company">Empresa</span>
                                <span>Cnpj</span>
                            </div>
                            <span>Status</span>
                        </div>
                        <div className="imported-list">
                            {customersImported.map( (customer, i ) =>(
                                <div className="item">
                                    <div className="data">
                                        <span className="company">{customer.company}</span>
                                        <span>{customer.document}</span>
                                    </div>
                                    {customer.status == 'ok'
                                        ?   <Tag color="green" className="tag">Importado</Tag>
                                        :   <Tag color="red" className="tag">Não importado</Tag>
                                    }
                                   
                                </div>
                            ))}
                        </div>
                        <Button className="close-button" type="default" onClick={()=>setModalImportCustomer(false)}>Fechar janela</Button>
                    </div>
                } 
        
            </Modal>
        </div>
    )       
}

export default AddCustomer;