import React, { useState, useContext } from 'react';
import { Form, Input, Button, Alert, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import ReactPasswordStrength from 'react-password-strength';


import api from '../../../api';

import '../login.css';
import logo from '../images/planos-vita.png';
import background from '../images/background-login.jpg';

function ResetPassword() {
    let { token } = useParams();
   // const { push } = useHistory()

    const [password, setPassword] = useState(false)
    const [error, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [passwordLevel, setPasswordLevel] = useState(0)

    const onFinish = async values => {
        console.log(values)
        if(passwordLevel >= 2){
            setLoading(true)
            api.defaults.headers.common['Authorization'] = token
            const data = await api.post('/users/reset-password', {password: password}).then( async response => { 
                setLoading(false)
                message.success(`Senha alterada com sucesso!`);
            }).catch(error => { 
                console.log(error) 
                setLoading(false)
                message.error('Erro ao alterar sua senha.');
            })
        }
    };

    function changePassword(score){
        setPassword(score.password)
        setPasswordLevel(score.score)
    }   

  return (
    <div className="login-container" style={{backgroundImage: `url(${background})`}}>
      
        <div className="login-box">
          <div className="login-box-inner">

            <img src={logo} alt="Logo" width="150" className="login-box-logo" />

            <h2>Recuperar Senha</h2>
            <h3>Digite sua nova senha no campo abaixo.</h3>

            <Form layout="vertical" name="password" onFinish={onFinish}>
              <Form.Item >
                <ReactPasswordStrength
                    className="customClass"
                    minLength={5}
                    minScore={2}
                    scoreWords={[ 'Fraca', 'Ainda não', 'Boa', 'Forte', 'Muito Forte']}
                    tooShortWord='Muito curta'
                    changeCallback={changePassword}
                    inputProps={{  autoComplete: "off", placeholder: "Sua nova senha"}}
                />
              </Form.Item>

              <div className="login-box-buttons">
                <Button type="primary" size="large" style={{width: '200px'}} htmlType="submit" loading={loading}> Enviar </Button>  
                <Link to="/"><Button type="link">Voltar para login</Button></Link>
              </div>
              
            </Form>

          </div>
        </div>
    
    </div>
  );

}

export default ResetPassword;
