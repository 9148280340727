import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Pagination, Spin } from 'antd';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import statusJson from '../../helpers/status.json';

import api from '../../api';
import SideBar from '../../components/common/sidebar';
import ContentHeader from '../../components/common/contentHeader';

import './goals.css';
import { PiTarget } from "react-icons/pi";
import { Context } from '../../context/AuthContext';

import Filter from './filter';
import EditGoal from './editGoal';
import AddGoal from './addGoal';
import List from './list';

function Goals() {
    const { userData } = useContext(Context);

    const [users, setUsers] = useState([])
    const [statusList, setStatusList] = useState([])
    const [goals, setGoals] = useState([])
    const [goal, setGoal] = useState({})
    const [loadGoals, setLoadGoals] = useState(true)
    const [totalGoals, setTotalGoals] = useState(0);
    const [modalGoal, setModalGoal] = useState(false);
    const [statusTotal, setStatusTotal] = useState(0);
    const [page, setPage] = useState(1)

    // Filters
    const [filters, setFilters] = useState({})

    //
    const location = useLocation();
    const query = queryString.parse(location.search);
    const goalSearchId = query.id

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');

            setUsers(data)

            // setUsers(data.filter(e => {
            //     if (userData.users?.length) {
            //         if (userData.users.includes(e._id)) {
            //             return e;
            //         }
            //     } else {
            //         return e;
            //     }
            // }));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/goals');
            setGoals(data.goals);
            setTotalGoals(data.total);
            setLoadGoals(false)
            // Se tiver id para buscar
            if (goalSearchId) {
                const { data } = await api.get(`/goal/${goalSearchId}`);
                setGoal(data)
                setModalGoal(true)
            }
        })();
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         const { data } = await api.get('/customers/totals/status');
    //         setStatusTotal(data);
    //     })();
    // }, []);

    useEffect(() => {
        (async () => {
            let _statusList = await statusJson.filter(status => {
                if (userData.roles.includes('call-center') && (status.id == 'agreed' || status.id == 'done')) {
                    return false
                } else {
                    return status
                }
            })
            setStatusList(_statusList)
        })();
    }, []);

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Anterior</a>;
        }
        if (type === 'next') {
            return <a>Próxima</a>;
        }
        return originalElement;
    }

    function selectGoal(id) {
        let goal = goals.filter((item) => {
            return item._id == id
        })[0]
        setGoal(goal)
        setModalGoal(true)
    }

    async function changePage(page) {
        setLoadGoals(true)

        let url = `/goals?page=${page}`

        if (filters.status)
            url = url.concat(`&status=${filters.status}`)

        if (filters.priority)
            url = url.concat(`&priority=${filters.priority}`)

        if (filters.responsible)
            url = url.concat(`&responsible=${filters.responsible}`)

        if (filters.search)
            url = url.concat(`&search=${filters.search}`)

        const { data } = await api.get(url);
        setGoals(data.goals);
        setPage(page)
        setLoadGoals(false)
    }

    return (
        <div className="page-content">
            <SideBar page="goals" />
            <div className="content">
                <ContentHeader title="Metas de gestão" icon={<PiTarget size="2em" />} />

                <div className="goals-container">

                    <Filter
                        setLoadGoals={setLoadGoals}
                        setTotalGoals={setTotalGoals}
                        //statusTotal={statusTotal}
                        //setStatusTotal={setStatusTotal}
                        //statusList={statusList}
                        goals={goals}
                        setGoals={setGoals}
                        filters={filters}
                        setFilters={setFilters}
                        users={users}
                    />


                    {loadGoals
                        ? <Spin tip="Carregando metas..."><div className="loading-goals"></div></Spin>
                        : <div className="goals-list">
                            <AddGoal goal={goal} goals={goals} setGoals={setGoals} setStatusTotal={setStatusTotal} />
                            <List goals={goals} setGoals={setGoals} selectGoal={selectGoal} />
                        </div>
                    }

                    {goals.length > 0 &&
                        <div className="customers-pagination">
                            <Pagination
                                onChange={(e) => changePage(e)}
                                total={totalGoals}
                                itemRender={itemRender}
                                current={page}
                                howSizeChanger={false}
                            />
                        </div>
                    }
                </div>

                {goal &&
                    <EditGoal
                        statusList={statusList}
                        goal={goal}
                        setGoal={setGoal}
                        setGoals={setGoals}
                        setStatusTotal={setStatusTotal}
                        modalGoal={modalGoal}
                        setModalGoal={setModalGoal}
                        page={page}
                        filters={filters}
                    />
                }
            </div>
        </div>
    )

}


export default Goals;
