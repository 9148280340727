import React, {useContext} from 'react';
import { Tag, Empty, Switch, message} from 'antd';
import { Context } from '../../../../context/AuthContext';

import MonetaryConverter from '../../../../helpers/monetaryConverter';
import api from '../../../../api';
import './list-plans.css';


function ListPlans (props){
    const { userData } = useContext(Context);

    const { plans, setPlans } = props;

    async function handleChange(checked, planId, wallet){
        const data = await api.put(`/plans/${planId}`, {
            active: !checked
        }).then( async response => { 
            const {data} = await api.get(`/plans/wallet/${wallet}`);
            setPlans(data)
            message.success('Plano atualizado com suceesso!');
        }).catch(error => { 
            console.log(error) 
            message.error('Erro ao criar plano.');
        })
    }
 
    return(
        plans.length > 0 &&
            plans.map((plan) => (
                    <div className="list-plan-item">
                        <div className="list-plan-data">
                            <p className="title">{plan.name}</p>
                            <p className="price"> <MonetaryConverter quantity={plan.price} /> </p>
                        </div>
                        { userData.roles.includes('admin') && 
                            <div className="list-plan-switch">
                                <p>Plano ativo:</p>
                                <Switch checked={plan.active}  onChange={ () => handleChange(plan.active, plan._id, plan.wallet)} checkedChildren="Sim" unCheckedChildren="Não">dqwopkdwq</Switch>                                
                            </div>
                        }
                    </div>      
                )     
            )
    )
}

export default ListPlans;
