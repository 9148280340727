import React from 'react';

function weekDayMask(props) {

    let { day } = props;

    switch (day) {
        case 'dom':
            return (<span>Domingo</span>);
        case 'seg':
            return (<span>Segunda-feira</span>);
        case 'ter':
            return (<span>Terça-feira</span>);
        case 'qua':
            return (<span>Quarta-feira</span>);
        case 'qui':
            return (<span>Quinta-feira</span>);
        case 'sex':
            return (<span>Sexta-feira</span>);
        case 'sab':
            return (<span>Sábado</span>);
        default:
            return (<span>---</span>);
    }

}

export default weekDayMask;