import React, { useContext, useState, useEffect } from 'react';
import { Card, Empty, Form, Input, Button, Select, Pagination, Spin } from 'antd';

import api from '../../api';
import SideBar from '../../components/common/sidebar';
import ContentHeader from '../../components/common/contentHeader';

import './wallets.css';
import { BiWalletAlt } from "react-icons/bi";
import { Context } from '../../context/AuthContext';

import AddWallet from './addWallet';
import EditWallet from './editWallet';
import List from './list';

function Customers(){
    const { userData } = useContext(Context);

    const [wallets, setWallets] = useState([])
    const [wallet, setWallet] = useState({})
    const [plans, setPlans]  = useState({})
    const [loadWallets, setLoadWallets] = useState(true)
    const [loadPlans, setLoadPlans] = useState(false);
    const [modalWallet, setModalWallet] = useState(false);

    useEffect( () => {
        (async () => {
            const {data} = await api.get('/wallets');
            setWallets(data);
            setLoadWallets(false)
        })();
    }, []);

    async function selectWallet(id){
        let wallet = wallets.filter( (item) => {
            return item._id == id
        })[0]
        setWallet(wallet)
        setModalWallet(true)
        setLoadPlans(true)
        const {data} = await api.get(`/plans/wallet/${id}`);
        setPlans(data)
        setLoadPlans(false)
    }
    
    return(
        <div className="page-content">
            <SideBar page="wallets" />
            <div className="content">
                <ContentHeader title="Carteiras" icon={<BiWalletAlt size="2em" />} />

                <div className="wallets-container"> 
                    { loadWallets 
                        ?   <Spin tip="Carregando carteiras..."><div className="loading-wallets"></div></Spin>
                        :   <div className="wallets-list">    
                            { userData.roles.includes('admin') && ( <AddWallet setWallets={setWallets} wallets={setWallets} /> )} 
                                <List wallets={wallets} selectWallet={selectWallet} />
                            </div>
                    }
                </div>

                {wallet && 
                    <EditWallet wallet={wallet} plans={plans} setPlans={setPlans} loadPlans={loadPlans} setWallet={setWallet} setWallets={setWallets} modalWallet={modalWallet} setModalWallet={setModalWallet} selectWallet={selectWallet} />
                }
                
            </div>
        </div>
    )

}


export default Customers;
