import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Select, Pagination, Spin, Tabs, Table, Tag, Space, Tooltip, Modal, Radio, message, Row, Col, Card } from 'antd';
import Moment from 'react-moment';

import api from '../../../api';
import RolesMask from '../../../helpers/rolesMask';

import './users.css';
import { BiPlusCircle, BiCog, BiEditAlt } from "react-icons/bi";
import { Context } from '../../../context/AuthContext';
import { value } from 'numeral';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';

// import Filter from './filter';
// import EditCustomer from './editCustomer';
// import AddCustomer from './addCustomer';
// import List from './list';

function Users() {
    const { userData } = useContext(Context);

    const [user, setUser] = useState({})
    const [modalUser, setModalUser] = useState(false);
    const [loadUser, setLoadUser] = useState(false)

    const [formEdit] = Form.useForm();

    function selectUser(id) {
        let user = users.filter((item) => {
            return item._id == id
        })[0]
        setUser(user)
        setRole(user.roles[0])

        formEdit.setFieldsValue({
            "name": user.name,
            "email": user.email,
            "role": user.roles[0],
        });

        // Set selected users
        if (user.roles[0] === "supervisor" && user.users.length) {
            user.users.forEach(userId => {
                selectUserList(userId);
            })
        }

        setModalUser(true)
    }

    const [users, setUsers] = useState([])
    const [modalNewUser, setModalNewUser] = useState(false)
    const [loadNewUser, setLoadNewUser] = useState(false)
    const [role, setRole] = useState('call-center')

    const [listUsers, setListUsers] = useState([]);
    const [totalUsersSelected, setTotalUsersSelected] = useState(0);

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users');
            setUsers(data);

            let _usersList = [];
            data.forEach(item => {
                _usersList.push({
                    id: item._id,
                    name: item.name,
                    email: item.email,
                    role: item.roles[0],
                    selected: false,
                    hidden: false
                })
            })
            console.log(_usersList)
            setListUsers(_usersList);

        })();
    }, []);

    const onFinish = async values => {
        const selectedUsers = listUsers.filter(user => user.selected)

        setLoadNewUser(true)
        let newUser = {
            name: values.name,
            email: values.email,
            password: values.password,
            role: role
        }

        if (selectedUsers.length) {
            newUser.users = selectedUsers.map(user => { return user.id })
        }

        await api.post('/users', newUser).then(async response => {
            const { data } = await api.get('/users');
            setUsers(data)
            setModalNewUser(false)
            setLoadNewUser(false)
            message.success('Usuário criado com suceesso!');
        }).catch(error => {
            console.log(error)
            setLoadNewUser(false)
            message.error('Erro ao criar novo cliente.');
        })
    };

    const onFinishUser = async values => {
        const selectedUsers = listUsers.filter(user => user.selected)

        if (selectedUsers.length) {
            values.users = selectedUsers.map(user => { return user.id })
        }

        setLoadUser(true)
        await api.patch(`/users/${user._id}`, values).then(async response => {
            const { data } = await api.get('/users');
            setUsers(data)
            setModalUser(false)
            setLoadUser(false)
            clearSelectedUsers()
            message.success('Usuário atualizado com suceesso!');
        }).catch(error => {
            console.log(error)
            setLoadNewUser(false)
            message.error('Erro ao criar novo cliente.');
        })
    };

    function changeRole(e) {
        setRole(e.target.value)
    }

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Permissões',
            key: 'roles',
            dataIndex: 'roles',
            render: tags => (
                <>
                    {tags.map(tag => {
                        let color = tag == 'call-center' ? 'geekblue' : 'cyan';
                        if (tag === 'admin') {
                            color = 'green';
                        }
                        if (tag === 'supervisor') {
                            color = 'magenta';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                <RolesMask user={tag} />
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Último login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            render: lastLogin => <span>{<Moment format="DD/MM/YYYY  H:mm">{lastLogin}</Moment>}</span>,
        },
        {
            title: 'Criado dia',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => <span>{<Moment format="DD/MM/YYYY">{createdAt}</Moment>}</span>,
        },
        {
            title: 'Ações',
            key: 'action',
            render: (text, record) => (
                <Tooltip title="Editar Usuário">
                    <Space size="middle">
                        <Button type="default" icon={<BiEditAlt />} onClick={() => selectUser(record._id)} size="small"></Button>
                    </Space>
                </Tooltip>
            ),
        },
    ];

    const selectUserList = async (userId) => {
        let _users = listUsers.map(item => {
            if (item.id == userId) {
                if (item.selected) {
                    item.selected = false;
                } else {
                    item.selected = true;
                }
            }
            return item;
        })
        setListUsers(_users);
        setTotalUsersSelected(_users.filter(item => item.selected).length);
    }

    const filterUsers = async (search) => {
        let _usersList = listUsers.map(item => {
            if (item.name.toLowerCase().search(search.toLowerCase()) !== -1 || item.email.toLowerCase().search(search.toLowerCase()) !== -1) {
                item.hidden = false;
            } else {
                item.hidden = true;
            }
            return item;
        });
        setListUsers(_usersList)
    }

    const clearSelectedUsers = async () => {
        let _users = listUsers.map(item => {
            item.selected = false;
            return item;
        })
        setListUsers(_users);
        setTotalUsersSelected(0);
    }

    return (
        <>
            <div className="add-user" onClick={() => setModalNewUser(true)}>
                <BiPlusCircle size="20px" />
                <span>Criar novo usuário</span>
            </div>
            <Table columns={columns} dataSource={users} />

            <Modal
                visible={modalNewUser}
                title="Criar novo usuário"
                onCancel={() => setModalNewUser(false)}
                footer={false}
                centered
                width={800}
                maskClosable={false}
            >
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={onFinish}
                    >
                        <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome é obrigatório' }]} >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email é obrigatório' }]} >
                            <Input />
                        </Form.Item>


                        <Form.Item label="Senha" name="password" rules={[{ required: true, message: 'Senha é obrigatório' }]} >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="Nível de acesso" name="role">
                            <Radio.Group defaultValue="call-center" buttonStyle="solid" onChange={(e) => changeRole(e)}>
                                <Radio.Button value="call-center" defaultChecked>Atendente</Radio.Button>
                                <Radio.Button value="specialist">Especialista</Radio.Button>
                                <Radio.Button value="supervisor">Supervisor</Radio.Button>
                                <Radio.Button value="admin">Administrador</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {role === "supervisor" &&
                            <Row>
                                <Col span={5}></Col>
                                <Col span={16}>
                                    <div className='select_users_list'>
                                        <p><i>Um usuário supervisor, precisa de usuários relacionados a ele, <br /> escolha os usuários na lista abaixo:</i></p>

                                        <p>Usuários selecionados: <strong>{totalUsersSelected}</strong>/{listUsers.length}</p>
                                        <Input
                                            prefix={<SearchOutlined />}
                                            placeholder="Filtrar usuários por nome ou e-mail"
                                            onChange={(e) => filterUsers(e.target.value)}
                                            allowClear
                                        />
                                        <ul>
                                            {listUsers && listUsers.map((user, i) => {
                                                let tag = user.role;
                                                let color = tag == 'call-center' ? 'geekblue' : 'cyan';
                                                if (tag === 'admin') color = 'green';
                                                if (tag === 'supervisor') color = 'magenta';

                                                return (
                                                    <li key={i} className={`${user.selected ? 'active' : ''} ${user.hidden ? 'hidden' : ''} `}>
                                                        <div className="selector" onClick={() => { selectUserList(user.id) }}>
                                                            <CheckOutlined style={{ fontSize: '14px' }} />
                                                        </div>
                                                        <div className='user'>
                                                            <div>
                                                                <span className='name'>{user.name}</span>
                                                                <span>{user.email}</span>
                                                            </div>
                                                            <Tag className='tag' color={color}>
                                                                <RolesMask user={user.role} />
                                                            </Tag>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" style={{ width: "200px" }} loading={loadNewUser}>Confirmar</Button>
                        </div>

                    </Form>
                </div>
            </Modal>

            <Modal
                visible={modalUser}
                title="Editar usuário"
                onCancel={() => {
                    setModalUser(false);
                    clearSelectedUsers();
                }}
                footer={false}
                centered
                width={800}
            >
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={onFinishUser}
                        form={formEdit}
                    >

                        <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome é obrigatório' }]} >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email é obrigatório' }]} >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Senha" name="password" >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="Nível de acesso" name="role">
                            <Radio.Group defaultValue="call-center" buttonStyle="solid" onChange={(e) => changeRole(e)}>
                                <Radio.Button value="call-center" defaultChecked>Atendente</Radio.Button>
                                <Radio.Button value="specialist">Especialista</Radio.Button>
                                <Radio.Button value="supervisor">Supervisor</Radio.Button>
                                <Radio.Button value="admin">Administrador</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {role === "supervisor" &&
                            <Row>
                                <Col span={5}></Col>
                                <Col span={16}>
                                    <div className='select_users_list'>
                                        <p><i>Um usuário supervisor, precisa de usuários relacionados a ele, <br /> escolha os usuários na lista abaixo:</i></p>

                                        <p>Usuários selecionados: <strong>{totalUsersSelected}</strong>/{listUsers.length}</p>
                                        <Input
                                            prefix={<SearchOutlined />}
                                            placeholder="Filtrar usuários por nome ou e-mail"
                                            onChange={(e) => filterUsers(e.target.value)}
                                            allowClear
                                        />
                                        <ul>
                                            {listUsers && listUsers.map((user, i) => {
                                                let tag = user.role;
                                                let color = tag == 'call-center' ? 'geekblue' : 'cyan';
                                                if (tag === 'admin') color = 'green';
                                                if (tag === 'supervisor') color = 'magenta';

                                                return (
                                                    <li key={i} className={`${user.selected ? 'active' : ''} ${user.hidden ? 'hidden' : ''} `}>
                                                        <div className="selector" onClick={() => { selectUserList(user.id) }}>
                                                            <CheckOutlined style={{ fontSize: '14px' }} />
                                                        </div>
                                                        <div className='user'>
                                                            <div>
                                                                <span className='name'>{user.name}</span>
                                                                <span>{user.email}</span>
                                                            </div>
                                                            <Tag className='tag' color={color}>
                                                                <RolesMask user={user.role} />
                                                            </Tag>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" style={{ width: "200px" }} loading={loadUser}>Confirmar</Button>
                        </div>

                    </Form>
                </div>
            </Modal>

        </>
    )

}


export default Users;
