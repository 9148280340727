import React, { useContext, useState, useEffect } from 'react';
import { Tag, Empty, Checkbox, Button, Select, Form, message, Modal, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import MonetaryConverter from '../../../helpers/monetaryConverter';
import FormatDocument from '../../../helpers/formatDocument';
import StatusMask from '../../../helpers/statusMask';
import Priority from '../../../helpers/priority';
import './list.css';
import api from '../../../api';
import { Context } from '../../../context/AuthContext';

function List(props) {
    const { userData } = useContext(Context);
    const { customers, setCustomers, selectCustomer } = props;
    const [responsibles, setResponsibles] = useState([]);
    const [responsible, setResponsible] = useState('');
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [loadingModal, setLoadgingModal] = useState(false)

    function changeSelectedCustomers(e, customer) {
        if (e.target.checked) {
            setSelectedCustomers([...selectedCustomers, customer])
            //console.log(selectedCustomers)
        } else {
            // Aqui vai tirar um customer
            //console.log(  selectedCustomers.filter( (item) => { if(item._id != customer._id){ return true }else{ return false }  } ) )
            let customers = selectedCustomers.filter((item) => item != customer)
            setSelectedCustomers(customers)
        }
    }

    function selectAllCustomers(e) {
        if (e.target.checked) {
            let _customers = []
            customers.filter(function (item) {
                _customers = [..._customers, item._id]
            })
            setSelectedCustomers(_customers)
        } else {
            setSelectedCustomers([])
        }
    }

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');
            setResponsibles(data);
        })();
    }, []);

    async function updateMany() {
        // console.log(responsible)
        // console.log(selectedCustomers)
        setLoadgingModal(true)
        if (!responsible) {
            setLoadgingModal(false)
            message.error('Selecione um responsável.');
        } else {
            let data = {
                field: 'responsible',
                value: responsible,
                customers: selectedCustomers
            }
            const updateCustomers = await api.put('/customers/many', data).then(async response => {
                const { data } = await api.get('/customers');
                setCustomers(data.customers)
                setSelectedCustomers([])
                setLoadgingModal(false)
                message.success('Clientes atualizados.');
            }).catch(error => {
                console.log(error)
                setLoadgingModal(false)
                message.error('Erro ao criar novo cliente.');
            })
        }
    }

    return (
        <>

            <div className={`customers-header ${userData.roles.includes('specialist') ? 'specialist' : ''}`}>
                {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                    <div className="customers-header-item select">
                        <Checkbox
                            checked={selectedCustomers.length == customers.length}
                            onChange={(event) => selectAllCustomers(event)} >
                        </Checkbox>
                    </div>
                }
                <div className="customers-header-item priority"></div>
                <div className="customers-header-item date"><span>Criado dia</span></div>
                <div className="customers-header-item company"><span>Empresa</span></div>
                <div className="customers-header-item status"><span>Status</span></div>
                <div className="customers-header-item plan"><span>Plano e Valor</span></div>
                <div className="customers-header-item state"><span>Local</span></div>
                <div className="customers-header-item lifes"><span>Vidas</span></div>
                {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                    <div className="customers-header-item value"><span>Atendente</span></div>
                }
                <div className="customers-header-item last-mod"><span>Última modif.</span></div>
                <div className="customers-header-item edit"><span>Editar</span></div>

            </div>

            {selectedCustomers.length > 0 &&
                <div className={`update-many-customers ${userData.roles.includes('specialist') ? 'specialist' : ''}`}>
                    <div className="info">
                        <span className="title">Selecionados:</span>
                        <span className="number">{selectedCustomers.length}</span>
                    </div>

                    <Form.Item label="Atendente" name="responsible" rules={[{ required: true, message: 'Atendente é obrigatório' }]}>
                        <Select placeholder="Selecione" onChange={(e) => setResponsible(e)} showSearch style={{ width: '200px' }}>
                            {responsibles.map((responsible) => (
                                <Select.Option value={responsible._id}>{responsible.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button type="primary" onClick={() => updateMany()}> Atualizar Selecionados</Button>

                </div>

            }


            {customers.length == 0
                ? <Empty description="Nenhum resultado." style={{ marginTop: "50px" }} />
                : customers.map((customer) => (
                    <div className={`customer ${selectedCustomers.find(item => item == customer._id) ? 'active' : ''} `} >
                        {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                            <div className="customer-item select">
                                <Checkbox
                                    checked={selectedCustomers.find(item => item == customer._id) ? true : false}
                                    onChange={(event) => changeSelectedCustomers(event, customer._id)}>
                                </Checkbox>
                            </div>
                        }
                        <div className="customer-item priority">
                            <Priority priority={customer.priority} tooltip />
                        </div>
                        <div className="customer-item date">
                            <span><Moment format="DD/MM/YYYY">{customer.createdAt}</Moment></span>
                        </div>
                        <div className="customer-item company">
                            <span><b>{customer.company}</b></span>
                            <br />
                            <span><FormatDocument cnpj={customer.document} /></span>
                        </div>
                        <div className="customer-item status">
                            <Tag className={`tag ${customer.status} customer-list`}><StatusMask status={customer.status} /></Tag>
                        </div>
                        <div className="customer-item plan">
                            {customer.plan
                                ? <>
                                    <span className="plan-name">{customer.plan}</span>
                                    <MonetaryConverter quantity={customer.price} class="plan-price" />
                                </>
                                : <span>-</span>
                            }
                        </div>
                        <div className="customer-item state">
                            <span>{customer.city} - {customer.state}</span>
                        </div>
                        <div className="customer-item lifes">
                            <span>{customer.lives}</span>
                        </div>
                        {(userData.roles.includes('admin') || userData.roles.includes('specialist')) &&
                            <div className="customer-item responsible">
                                {customer.responsible
                                    ? <span className="responsible-name">{customer.responsible.name}</span>
                                    : '---'
                                }
                            </div>
                        }
                        <div className="customer-item last-mod">
                            <Moment format="DD/MM/YYYY hh:mm">{customer.updatedAt}</Moment>
                        </div>
                        <div className="customer-item edit">
                            <Button type="default" shape="circle" icon={<EditOutlined />} size="small" onClick={() => selectCustomer(customer._id)} />
                        </div>
                    </div>
                )
                )}


            <Modal
                header={false}
                footer={false}
                keyboard={false}
                closable={false}
                centered={true}
                visible={loadingModal}
            >
                <div className="example" style={{ textAlign: 'center' }}>
                    <Spin tip="Atualizando clientes..." />
                </div>
            </Modal>
        </>
    )
}

export default List;
