import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';


import { Context } from './context/AuthContext';
import Login from './pages/login';
import ForgotPassword from './pages/login/forgotPassword';
import ResetPassword from './pages/login/resetPassword';
import Customers from './pages/customers';
import Wallets from './pages/wallets';
import Configurations from './pages/configurations';
import Goals from './pages/goals';

function CustomRoute({ isPrivate, ...rest }) {
    const { loading, authenticated } = useContext(Context);

    if (loading) {
        return <div className="page-loading"> <Spin tip="Carregando página..." /> </div>
    }

    if (isPrivate && !authenticated) {
        return <Redirect to="/" />
    }

    return <Route {...rest} />
}

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <CustomRoute isPrivate exact path="/customers" component={Customers} />
            <CustomRoute isPrivate exact path="/customers/:id" component={Customers} />
            <CustomRoute isPrivate exact path="/goals" component={Goals} />
            <CustomRoute isPrivate exact path="/wallets" component={Wallets} />
            <CustomRoute isPrivate exact path="/configs" component={Configurations} />
        </Switch>
    )
}