import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Pagination, Spin, Tabs, Table, Tag, Space, Tooltip, Modal, Radio, message } from 'antd';
//import Moment from 'react-moment';

//import api from '../../api';
import SideBar from '../../components/common/sidebar';
import ContentHeader from '../../components/common/contentHeader';
//import RolesMask from '../../helpers/rolesMask';

import './configurations.css';
import { BiPlusCircle, BiCog, BiEditAlt} from "react-icons/bi";
import { Context } from '../../context/AuthContext';
//import { value } from 'numeral';

import Users from './users';
import Acount from './acount';

function Configurations(){
    const { userData } = useContext(Context);

    // const [user, setUser] = useState({})
    // const [modalUser, setModalUser] = useState(false);
    // const [loadUser, setLoadUser] = useState(false)

    // function selectUser(id){
    //     let user = users.filter( (item) => {
    //         return item._id == id
    //     })[0]
    //     setUser(user)
    //     console.log(user)
    //     setModalUser(true)
    //  }

    // const [users, setUsers] = useState([])
    // const [modalNewUser, setModalNewUser] = useState(false)
    // const [loadNewUser, setLoadNewUser] = useState(false)
    // const [role, setRole] = useState('call-center')

    // useEffect( () => {
    //     (async () => {
    //         const {data} = await api.get('/users');
    //         setUsers(data);
    //     })();
    // }, []);

    // const onFinish = async values => {
    //     setLoadNewUser(true)
    //     let newUser = {
    //         name: values.name,
    //         email: values.email,
    //         password: values.password,
    //         role: role
    //     }

    //     const data = await api.post('/users', newUser).then( async response => { 
    //         const {data} = await api.get('/users');
    //         setUsers(data)
    //         setModalNewUser(false)
    //         setLoadNewUser(false)
    //         message.success('Usuário criado com suceesso!');
    //     }).catch(error => { 
    //         console.log(error) 
    //         setLoadNewUser(false)
    //         message.error('Erro ao criar novo cliente.');
    //     })
    // };

    // const onFinishUser = async values => {
    //     setLoadUser(true)
    //     const data = await api.put(`/users/role/${user._id}`, {role}).then( async response => { 
    //         const {data} = await api.get('/users');
    //         setUsers(data)
    //         setModalUser(false)
    //         setLoadUser(false)
    //         message.success('Usuário atualizado com suceesso!');
    //     }).catch(error => { 
    //         console.log(error) 
    //         setLoadNewUser(false)
    //         message.error('Erro ao criar novo cliente.');
    //     })
    // };

    // function changeRole(e){
    //     setRole(e.target.value)
    // }
    
    // const columns = [
    //     {
    //       title: 'Nome',
    //       dataIndex: 'name',
    //       key: 'name'
    //     },
    //     {
    //       title: 'Email',
    //       dataIndex: 'email',
    //       key: 'email',
    //     },
    //     {
    //       title: 'Permissões',
    //       key: 'roles',
    //       dataIndex: 'roles',
    //       render: tags => (
    //         <>
    //           {tags.map(tag => {
    //             let color = tag==('call-center') ? 'geekblue' : 'cyan';
    //             if (tag === 'admin') {
    //               color = 'green';
    //             }
    //             return (
    //               <Tag color={color} key={tag}>
    //                 <RolesMask user={tag} />
    //               </Tag>
    //             );
    //           })}
    //         </>
    //       ),
    //     },
    //     {
    //         title: 'Criado dia',
    //         dataIndex: 'createdAt',
    //         key: 'createdAt',
    //         render: createdAt => <span>{<Moment format="DD/MM/YYYY">{createdAt}</Moment>}</span>,
    //     },
    //     {
    //       title: 'Ações',
    //       key: 'action',
    //       render: (text, record) => (
    //         <Tooltip title="Editar Usuário">
    //             <Space size="middle">
    //                 <Button type="default" icon={<BiEditAlt />} onClick={()=>selectUser(record._id)} size="small"></Button>
    //             </Space>
    //         </Tooltip>
    //       ),
    //     },
    //   ];
      
    return(
        <div className="page-content">
            <SideBar page="configs" />
            <div className="content">
                <ContentHeader title="Configurações" icon={<BiCog size="2em" />} />

                <div className="configs-container"> 

                    <Tabs defaultActiveKey="1"  tabPosition="left">
                        
                        { userData.roles.includes('admin') && 
                            <Tabs.TabPane tab="Usuários" key="2">
                                <div className="tab-body">
                                    <Users />
                                </div>
                            </Tabs.TabPane>
                        }

                        {/* <Tabs.TabPane tab="Minha Conta" key="3">
                            <div className="tab-body">
                                <Acount />
                             </div>
                        </Tabs.TabPane> */}

                    </Tabs>

                </div>

            </div>

{/* 
            <Modal
                visible={modalNewUser}
                title="Criar novo usuário"
                onCancel={()=>setModalNewUser(false)}
                footer={false}
                centered
                width={800}
                maskClosable={false}
            >              
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={onFinish}
                    >
                        <Form.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome é obrigatório' }]} >
                            <Input  />
                        </Form.Item>
                        
                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email é obrigatório' }]} >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Senha" name="password" rules={[{ required: true, message: 'Senha é obrigatório' }]} >
                            <Input.Password  />
                        </Form.Item>
                        
                        <Form.Item label="Nível de acesso" >
                            <Radio.Group defaultValue="call-center" buttonStyle="solid" onChange={(e)=>changeRole(e) }>
                                <Radio.Button value="call-center" defaultChecked>Atendente</Radio.Button>
                                <Radio.Button value="specialist">Especialista</Radio.Button>
                                <Radio.Button value="admin">Administrador</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" style={{width: "200px"}} loading={loadNewUser}>Confirmar</Button>
                        </div>

                    </Form>
                </div>
            </Modal>

            <Modal
                visible={modalUser}
                title="Editar usuário"
                onCancel={()=>setModalUser(false)}
                footer={false}
                centered
                width={700}
            >              
                <div className="add-customer-step-1">
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        onFinish={onFinishUser}
                    >                       
                        <Form.Item label="Nível de acesso" >
                            <Radio.Group defaultValue="call-center" buttonStyle="solid" onChange={(e)=>changeRole(e) }>
                                <Radio.Button value="call-center" defaultChecked>Atendente</Radio.Button>
                                <Radio.Button value="specialist">Especialista</Radio.Button>
                                <Radio.Button value="admin">Administrador</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <div className="add-customer-form-footer">
                            <Button className="green" type="primary" htmlType="submit" style={{width: "200px"}} loading={loadUser}>Confirmar</Button>
                        </div>

                    </Form>
                </div>
            </Modal> */}

        </div>
    )

}


export default Configurations;
