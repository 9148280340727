import React from 'react';

function rolesMask(props) {

    let { user } = props;

    switch (user) {
        case 'call-center':
            return (<span>Atendente</span>);
        case 'specialist':
            return (<span>Especialista</span>);
        case 'supervisor':
            return (<span>Supervisor</span>);
        case 'admin':
            return (<span>Administrador</span>);
        default:
            return (<span>---</span>);
    }

}

export default rolesMask;