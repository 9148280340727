import React, { useContext, useState, useEffect } from 'react';
import { Avatar, Menu, Dropdown, Badge, List, Spin, Tag, Tooltip, message } from 'antd';
import { UserOutlined, DownOutlined, BellOutlined, LoadingOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { BiBell, BiReceipt, BiCheck } from "react-icons/bi";
//import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from "react-infinite-scroll-component";

import { Link } from 'react-router-dom';


import api from '../../../api';
import { Context } from '../../../context/AuthContext';

import './header.css';

function ContentHeader(props) {
  const { userData, handleLogout } = useContext(Context);

  // Filters
  const [loadNotifications, setLoadNotifications] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [totalNotifications, setTotalNotifications] = useState({})
  const [notificationsUnread, setNotificationsUnread] = useState({})
  const [notificationsPage, setNotificationsPage] = useState({})
  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/notifications?page=1');
      setNotifications(data.notifications);
      setTotalNotifications(data.total);
      setNotificationsUnread(data.unread)
      setLoadNotifications(false)
      setPage(1)
    })();
  }, []);

  async function loadMoreNotifications() {
    if (notifications.length >= totalNotifications) {
      setHasMore(false);
      return;
    }

    setPage(page + 1)

    const { data } = await api.get(`/notifications?page=${page}`);
    setNotifications([...notifications, ...data.notifications]);
  }


  async function clickNotification(id, path) {
    const data = await api.put(`notifications?id=${id}`)
    let url = `${process.env.REACT_APP_BASE_URL}/${path}`
    deleteNotification(id)
    document.location = `/${path}`;
  }

  async function deleteNotification(id) {
    await api.delete(`notifications?id=${id}`)
    message.success("Notificação excluída!")
    const { data } = await api.get('/notifications?page=1');
    setTotalNotifications(data.total);
    setNotificationsUnread(data.unread)

    setNotifications(
      notifications.filter((item) => {
        return item._id != id
      })
    )
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <a>Minha Conta</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={() => handleLogout()}>
          Sair
        </a>
      </Menu.Item>
    </Menu>
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
  const loader = <div className="loader" style={{ display: 'block', margin: '10px  auto', textAlign: 'center' }}>{antIcon}</div>;

  const notificationList = (
    <>
      <InfiniteScroll
        dataLength={notifications.length}
        next={loadMoreNotifications}
        hasMore={hasMore}
        loader={loader}
        height={300}
        endMessage={
          <p style={{ textAlign: "center", color: '#bebebe' }}>
            <b>Você já viu tudo ;)</b>
          </p>
        }
      >
        <List
          header={
            <div className="menu-notifications-header">
              <b>Notificações ({notificationsUnread})</b>
              {/* <Tooltip placement="left" title="Marcar todas como lidas">
                    <Tag className="icon-notification"><BiCheck size="20px" />  </Tag>
                  </Tooltip> */}
            </div>
          }
          itemLayout="horizontal"
          dataSource={notifications}
          className="notifications-list"
          renderItem={item => (
            <List.Item
              className="notifications-list-item"
              actions={[
                <Tooltip title="Ver e editar cliente">
                  <a key="list-loadmore-edit" onClick={() => clickNotification(item._id, item.path)}><EditOutlined /></a>
                </Tooltip>,
                <Tooltip title="Excluir da lista" onClick={() => deleteNotification(item._id)}>
                  <a key="list-loadmore-more"><DeleteOutlined style={{ color: '#ff4d4f' }} /></a>
                </Tooltip>
              ]}
            >
              <List.Item.Meta
                avatar={<BiReceipt size="15px" />}
                title={item.title}
                description={item.description}
              />
              {!item.read && <Badge status="processing" />}
            </List.Item>
          )}
        />
      </InfiniteScroll>

      {/* <div className="demo-infinite-container">
          <InfiniteScroll
              initialLoad={false} 
              pageStart={0}
              loadMore={(e) => loadMoreNotifications(e)}
              hasMore={hasMore} 
             //loader={loader}
              initialLoad={true}
          >
            <List
              header={
                <div className="menu-notifications-header">
                  <b>Notificações ({notificationsUnread})</b>   
                  <Tooltip placement="left" title="Marcar todas como lidas">
                    <Tag className="icon-notification"><BiCheck size="20px" />  </Tag>
                  </Tooltip>
                </div> 
              }
              itemLayout="horizontal"
              dataSource={notifications}
              className="notifications-list"
              renderItem={item => (
                <List.Item className="notifications-list-item" onClick={() => clickNotification(item._id, item.path) }>
                  <List.Item.Meta
                    avatar={ <BiReceipt size="15px" /> }
                    title={item.title} 
                    description={item.description}
                  />
                  { !item.read &&  <Badge status="processing" /> }
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div> */}
    </>
  );

  return (
    <div className="header">

      <div className="header-title">
        {props.icon}
        <p>{props.title}</p>
      </div>

      <div className="header-menu">
        {!loadNotifications && userData.roles.includes('call-center') &&
          <div className="menu-notifications">
            <Dropdown overlay={notificationList} arrow trigger={['click']} placement="bottomRight" overlayClassName="dropdown-notificatons" >
              <Badge count={notificationsUnread} size="small" >
                <BiBell size="20px" />
              </Badge>
            </Dropdown>
          </div>
        }

        <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']} className="dropdown-user">
          <div className="user-menu">
            <p>{userData.name}</p>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>

    </div>
  )
}

export default ContentHeader;