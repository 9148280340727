import React from 'react';

function formatPhone(props){
    var {number} = props;
        if(number.length > 0){
        var r = number.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
            r = r.replace(/^(\d*)/, "($1");
        }

        return(
        <span>{ r }</span>
        )
    }else{
        return(
            <span></span>
        )
    }
}

export default formatPhone;