import React from 'react';
import { Tooltip } from 'antd';

function priority(props){
 
    switch(props.priority){
        case 'high':
            return (
                <>
                    {props.tooltip &&  
                        <Tooltip title="Prioridade Alta"> 
                            <span className="priority">
                                <img src="/images/priority/highest.svg" width={16} />
                            </span>
                        </Tooltip> 
                    }
                    {props.text &&  
                        <span className="priority">
                            <img src="/images/priority/highest.svg" width={18} style={{marginRight: 5}} />
                            {props.text && <span>Alta</span> }
                        </span>
                    }
                </> 
            );
        case 'medium':
            return (
                <>
                    {props.tooltip &&  
                        <Tooltip title="Prioridade Média"> 
                            <span className="priority">
                                <img src="/images/priority/medium.svg" width={16} />
                            </span>
                        </Tooltip> 
                    }
                    {props.text &&  
                        <span className="priority">
                            <img src="/images/priority/medium.svg" width={18} style={{marginRight: 5}} />
                            {props.text && <span>Media</span> }
                        </span>
                    }
                </> 
            ); 
        case 'low':
            return (
                <>
                    {props.tooltip &&  
                        <Tooltip title="Prioridade Baixa"> 
                            <span className="priority">
                                <img src="/images/priority/lowest.svg" width={16} />
                            </span>
                        </Tooltip> 
                    }
                    {props.text &&  
                        <span className="priority">
                            <img src="/images/priority/lowest.svg" width={18} style={{marginRight: 5}} />
                            {props.text && <span>Baixa</span> }
                        </span>
                    }
                </> 
                
            );
        default:  
            return (
                <>
                    {/* {props.tooltip &&  
                        <Tooltip title="Prioridade Média"> 
                            <span className="priority">
                                <img src="/images/priority/medium.svg" width={16} />
                            </span>
                        </Tooltip> 
                    }
                    {props.text &&  
                        <span className="priority">
                            <img src="/images/priority/medium.svg" width={18} style={{marginRight: 5}} />
                            {props.text && <span>Media</span> }
                        </span>
                    } */}
                </> 
                
            );  
    }
    
}

export default priority;