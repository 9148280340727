import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker } from 'antd';
import api from '../../../api';
import { Context } from '../../../context/AuthContext';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import './filter.css';

function Filter(props) {

    const { userData } = useContext(Context);

    const [wallets, setWallets] = useState([])
    const [loading, setLoading] = useState(false)

    const [wallet, setWallet] = useState()
    const [status, setStatus] = useState()
    const [weekDay, setWeekDay] = useState()
    const [priority, setPriority] = useState()
    const [responsible, setResponsible] = useState()
    const [search, setSearch] = useState()

    const [filters, setFilters] = useState(false)

    // form
    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/wallets');
            setWallets(data);
        })();
    }, []);

    const onFinish = async values => {
        console.log(props.filters)
        props.setLoadGoals(true)

        let url = "/goals?";
        let filters = {}


        if (values.status) {
            setStatus(values.status)
            url = url.concat(`&status=${values.status}`)
            filters.status = values.status
        }

        if (values.weekDay) {
            setWeekDay(values.weekDay)
            url = url.concat(`&weekDay=${values.weekDay}`)
            filters.weekDay = values.weekDay
        }

        if (values.priority) {
            setPriority(values.priority)
            url = url.concat(`&priority=${values.priority}`)
            filters.priority = values.priority
        }
        if (values.responsible) {
            setResponsible(values.responsible)
            url = url.concat(`&responsible=${values.responsible}`)
            filters.responsible = values.responsible
        }
        if (values.search) {
            setSearch(values.search)
            url = url.concat(`&search=${encodeURIComponent(values.search)}`)
            filters.search = values.search
        }
        // if (values.dateFrom && values.dateUntil) {
        //     setSearch(values.search)
        //     url = url.concat(`&dateFrom=${moment(values.dateFrom).format("DD/MM/YYYY")}&dateUntil=${moment(values.dateUntil).format("DD/MM/YYYY")}`)
        //     filters.dateFrom = values.dateFrom
        //     filters.dateUntil = values.dateUntil
        // }

        const { data } = await api.get(url);
        props.setFilters(filters)
        props.setGoals(data.goals)
        //props.setTotalCustomers(data.total)
        props.setLoadGoals(false)
        setFilters(true)
    };

    async function clearFilter() {
        form.resetFields();
        props.setLoadGoals(true)
        setStatus()
        setPriority()
        setSearch()
        setResponsible()

        const { data } = await api.get("/goals");
        props.setGoals(data.goals)
        props.setTotalGoals(data.total)

        props.setLoadGoals(false)
        setFilters(false)
        props.setFilters({})
    }

    return (
        <>
            <div className={`customers-filter ${props.goals.length > 0 ? '' : 'empty'} `}>
                <Form form={form} layout="vertical" onFinish={onFinish}>

                    {/* <Form.Item label="Data de" name="dateFrom">
                        <DatePicker
                            format="DD/MM/YYYY"
                            locale={locale}
                            allowEmpty={false}
                            allowClear={false}
                            disabledDate={d => d > moment()}
                        />
                    </Form.Item>
                    <Form.Item label="Data até" name="dateUntil">
                        <DatePicker
                            format="DD/MM/YYYY"
                            locale={locale}
                            allowEmpty={false}
                            allowClear={false}
                            disabledDate={d => d > moment()}
                        />
                    </Form.Item> */}

                    <Form.Item label="Status" name="status">
                        <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="Todos"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="">Todos</Select.Option>
                            <Select.Option value="waiting">Aguardando</Select.Option>
                            <Select.Option value="analysis">Em análise</Select.Option>
                            <Select.Option value="return">Retorno</Select.Option>
                            <Select.Option value="changing">Em troca</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Dia da semana" name="weekDay">
                        <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="Todos"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="">Todos</Select.Option>
                            <Select.Option value="dom">Domingo</Select.Option>
                            <Select.Option value="seg">Segunda-feira</Select.Option>
                            <Select.Option value="ter">Terça-feira</Select.Option>
                            <Select.Option value="qua">Quarta-feira</Select.Option>
                            <Select.Option value="qui">Quinta-feira</Select.Option>
                            <Select.Option value="sex">Sexta-feira</Select.Option>
                            <Select.Option value="sab">Sábado</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Prioridade" name="priority">
                        <Select
                            showSearch
                            style={{ width: 100 }}
                            placeholder="Todos"
                            optionFilterProp="children"
                        >
                            <Select.Option value="">Todas</Select.Option>
                            <Select.Option value="high">Alta</Select.Option>
                            <Select.Option value="medium">Media</Select.Option>
                            <Select.Option value="low">Baixa</Select.Option>
                        </Select>
                    </Form.Item>
                    {(userData.roles.includes('admin') || userData.roles.includes('specialist') || userData.roles.includes('supervisor')) &&
                        <Form.Item label="Atendente" name="responsible">
                            <Select
                                showSearch
                                style={{ width: 150 }}
                                placeholder="Todos"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">Todos</Select.Option>
                                {userData.roles[0] != "supervisor" &&
                                    <Select.Option value="empty">Vazio</Select.Option>
                                }
                                {props.users &&
                                    props.users.map((user) => (
                                        <Select.Option key={user._id} value={user._id}>{user.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    }
                    <Form.Item label="Palavra chave" name="search">
                        <Input
                            placeholder="Email, Proposta, Origem"
                        />
                    </Form.Item>

                    <Form.Item style={{ marginRight: 0 }} label=" ">
                        <Button type="default" disabled={!filters} onClick={() => clearFilter()} style={{ width: 140 }}>Limpar Filtros</Button>
                    </Form.Item>

                    <Form.Item style={{ marginRight: 0 }} label=" ">
                        <Button type="primary" htmlType="submit" loading={loading} style={{ width: 140 }}>Filtrar Metas</Button>
                    </Form.Item>

                </Form>
            </div>


            {/* {props.customers.length > 0 &&
                <div className="customers-totals">
                    <div className="item first">
                        <span className="status-name">Total:</span>
                        <span className="status-number">{props.statusTotal.total}</span>
                    </div>
                    <div className="status-list">
                        {props.statusTotal.firstContact > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#333' }}></span>
                                <span className="status-name">1° Contato:</span>
                                <span className="status-number">{props.statusTotal.firstContact}</span>
                            </div>
                        }
                        {props.statusTotal.created > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#1890ff' }}></span>
                                <span className="status-name">Prospecção:</span>
                                <span className="status-number">{props.statusTotal.created}</span>
                            </div>
                        }
                        {props.statusTotal.analyze > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#ccc' }}></span>
                                <span className="status-name">Em análise:</span>
                                <span className="status-number">{props.statusTotal.analyze}</span>
                            </div>
                        }
                        {props.statusTotal.interest > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#69cb3f' }}></span>
                                <span className="status-name">Interesse:</span>
                                <span className="status-number">{props.statusTotal.interest}</span>
                            </div>
                        }
                        {props.statusTotal.negotiation > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#0fc7c5' }}></span>
                                <span className="status-name">Em negociação:</span>
                                <span className="status-number">{props.statusTotal.negotiation}</span>
                            </div>
                        }
                        {props.statusTotal.notInterested > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#faad14' }}></span>
                                <span className="status-name">Sem interesse:</span>
                                <span className="status-number">{props.statusTotal.notInterested}</span>
                            </div>
                        }
                        {props.statusTotal.fail > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#f5222d' }}></span>
                                <span className="status-name">Falha no Contato:</span>
                                <span className="status-number">{props.statusTotal.fail}</span>
                            </div>
                        }
                        {props.statusTotal.returns > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#eb2f96' }}></span>
                                <span className="status-name">Retornos:</span>
                                <span className="status-number">{props.statusTotal.returns}</span>
                            </div>
                        }
                        {props.statusTotal.visit > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#e85d04' }}></span>
                                <span className="status-name">Visita:</span>
                                <span className="status-number">{props.statusTotal.visit}</span>
                            </div>
                        }
                        {props.statusTotal.disease > 0 &&
                            <div className="item">
                                <span className="status-icon" style={{ background: '#722ed1' }}></span>
                                <span className="status-name">Doença pré existente:</span>
                                <span className="status-number">{props.statusTotal.disease}</span>
                            </div>
                        }
                    </div>
                </div>
            } */}


        </>
    )
}

export default Filter;
